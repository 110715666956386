import React from 'react';
import { FaUser, FaFileAlt, FaPassport, FaMoneyBillAlt, FaIdCard, FaBirthdayCake, FaFileSignature, FaHandshake, FaUserPlus, FaFileInvoice, FaTicketAlt, FaPlane, FaHotel, FaBookReader, FaBusinessTime, FaMedkit, FaHome, FaHandsHelping, FaGraduationCap, FaBuilding, FaUserAlt, FaHands, FaRegMoneyBillAlt, FaSwimmer, FaUtensils, FaToolbox } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import ScrollButton from './goTop';

const Schemes = () => {
  return (
    <div className='bg-gray-100'>
      <NavBar />
      <div className="py-12" id="services">
        <section className="px-4 md:px-12">
          <div className="mb-8">
            <h2 className="text-3xl text-center text-custom-blue font-bold mb-4">
              <Link to="/EdistrictServices" className="text-custom-blue hover:underline">
                Services
              </Link>{" "}
              |{" "}
              <Link to="/contact" className="text-custom-blue hover:underline">
                Schemes
              </Link>
            </h2>
            <div className="flex justify-center items-center mb-4">
              <div className="w-16 h-2 bg-custom-blue"></div>
              <div className="mx-4 w-16 h-2 bg-custom-green"></div>
            </div>
            <div className="my-4 py-4">
              <h2 className="my-4 text-center text-3xl text-custom-blue font-bold">
                <Link to="/EdistrictServices" className="my-2 text-center text-3xl text-custom-blue font-bold ">
                  Services
                </Link>{" "}
                |{" "}
                <Link to="/Schemes" className="my-2 text-center text-3xl text-custom-blue font-bold underline">
                  Schemes
                </Link>
              </h2>
              <div className="flex justify-center items-center">
                <div className="w-24 h-2 bg-custom-blue"></div>
                <div className="mx-4 w-24 h-2 bg-custom-green"></div>
              </div>
              <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-custom-blue">
                Explore our services
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {/* Map through your service cards */}
            {serviceData.map((service, index) => (
              <ServiceCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
                link="/contact"
              />
            ))}
          </div>
        </section>
        <section data-aos="zoom-in-down">


          <div className="px-12" data-aos="fade-down" data-aos-delay="600">


            {/* Additional Headings and Service Cards */}
            <div className="container mx-auto px-4 py-8">
              <div className="mt-8">
                <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Small Business/Employment</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                  <ServiceCard
                    icon={<FaMoneyBillAlt className='text-4xl text-yellow-600' />}
                    title="Shop Construction/Shop-Operation Scheme"
                    description="You will be provided with financial assistance ranging from Rs 10000 to Rs 20000."
                    link="/income-certificate"
                    color="text-yellow-600"
                  />
                  <ServiceCard
                    icon={<FaHome className='text-4xl text-green-600' />}
                    title="Pradhan Mantri Mudra Yojana"
                    description="You will get financial assistance up to Rs 10 lakhs."
                    link="/domicile-certificate"
                    color="text-green-600"
                  />
                  <ServiceCard
                    icon={<FaIdCard className='text-4xl text-blue-600' />}
                    title="Mukhyamantri Swarojga"
                    description="You will receive grant up to Rs 2.5 lakhs for service enterprise."
                    link="/caste-certificate"
                    color="text-blue-600"
                  />
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Health and Medical</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                  <ServiceCard
                    icon={<FaFileInvoice className='text-4xl text-purple-600' />}
                    title="Disability (Due to) Leprosy Nutrition Grant Scheme"
                    description="You will receive grant of Rs 2500."
                    link="/duplicate-certificate"
                    color="text-purple-600"
                  />
                  <ServiceCard
                    icon={<FaFileSignature className='text-4xl text-pink-600' />}
                    title="Artificial Limbs/Assistive Equipments Scheme"
                    description="You will receive various types of equipment for different disabilities and financial grant upto Rs 8000."
                    link="/rent-agreement-commercial"
                    color="text-pink-600"
                  />
                  <ServiceCard
                    icon={<FaFileSignature className='text-4xl text-red-600' />}
                    title="Corrective Surgery treat"
                    description="You will receive grant of Rs"
                    link="/rent-agreement-commercial"
                    color="text-red-600"
                  />
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Education</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                  <ServiceCard
                    icon={<FaHandshake className='text-4xl text-orange-600' />}
                    title="Ganesh Shankar Vidyarthi Shramik Reward Rashi Scheme"
                    description="You will receive monetary benefit up to Rs 7000."
                    link="/education-gap-affidavit"
                    color="text-orange-600"
                  />
                  <ServiceCard
                    icon={<FaUserPlus className='text-4xl text-indigo-600' />}
                    title="Financial Assistance for Brilliant Students"
                    description="You will be provided with financial assistance ranging from Rs 3000 to Rs 5000."
                    link="/education-loan-affidavit"
                  />
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Housing</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                  <ServiceCard
                    icon={<FaPassport className='text-4xl text-teal-600' />}
                    title="Shauchalay Sahayata Yojana"
                    description="You will receive financial assistance of Rs 12000 for toilet construction."
                    link="/jeevan-praman"
                    color="text-teal-600"
                  />
                  <ServiceCard
                    icon={<FaPassport className='text-4xl text-cyan-600' />}
                    title="Swachh Bharat Mission – Grameen Phase II"
                    description="You will receive financial assistance up to Rs 12000 for construction of individual household toilet."
                    link="/jeevan-praman"
                    color="text-cyan-600"
                  />
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Assistance/Support To Victims</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                  <ServiceCard
                    icon={<FaPlane className='text-4xl text-blue-600' />}
                    title="Free Motorized Tricycle Scheme for Divyangjan"
                    description="You will receive a free motorized tricycle."
                    link="/passport"
                    color="text-blue-600"
                  />
                  <ServiceCard
                    icon={<FaPlane className='text-4xl text-purple-600' />}
                    title="Swadhar Greh Yojana"
                    description="You will get temporary residential accommodation with the provision of food, clothing, medical facilities, free electricity connection, vocational, skill up-gradation, and behavioral training, counseling, legal aid and guidance."
                    link="/passport"
                    color="text-purple-600"
                  />
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Agriculture</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                  {/* Add ServiceCard components for Agriculture here if needed */}
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
      <Footer />
      <ScrollButton/>
    </div>
  );
}

const ServiceCard = ({ icon, title, description, link, color }) => (
  <Link to="/contact" className="flex flex-col items-center bg-white text-gray-700 rounded-lg shadow-xl p-4 group transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl hover:bg-purple-900 hover:text-white">
    <div className="flex items-center justify-center bg-gray-200 rounded-full w-16 h-16 mb-4">
      {icon}
    </div>
    <h2 className="font-semibold text-lg text-center">{title}</h2>
    <p className="text-sm text-center mt-2">{description}</p>
  </Link>
);

// Define your service data with icons
const serviceData = [
  {
    icon: <FaBusinessTime className="text-4xl text-blue-500" />,
    title: "Small Business/Employment",
    description: "Get personalized affidavits tailored to your needs.",
    link: "/personal-affidavits"
  },
  {
    icon: <FaMedkit className="text-4xl text-green-500" />,
    title: "Health and Medical",
    description: "Documentation for births and deaths with legal support.",
    link: "/birth-death-affidavits"
  },
  {
    icon: <FaGraduationCap className="text-4xl text-yellow-500" />,
    title: "Education",
    description: "Legal documents certified by authorized entities.",
    link: "/affidavits"
  },
  {
    icon: <FaHome className="text-4xl text-purple-500" />,
    title: "Housing",
    description: "Certification for pensioners under digital platforms.",
    link: "/jeevan-praman"
  },
  {
    icon: <FaHandsHelping className="text-4xl text-red-500" />,
    title: "Assistance/Support To Victims",
    description: "Facilitation services for obtaining passports.",
    link: "/passport"
  },
  {
    icon: <FaUtensils className="text-4xl text-indigo-500" />,
    title: "Agriculture",
    description: "Explore additional services for various needs.",
    link: "/other-services"
  },
];

export default Schemes;
