import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// All pages
import Home from './pages/Home';
import ContactPage1 from './pages/ContactPage1';
import DemoProduct from './pages/DemoProduct';
import INCOMETAXFILING from './components/INCOMETAXFILING';
import ISOCertification from './components/PopularServices/ISOCertification';
import Msme_registration from './components/PopularServices/Msme_registration';
import Private_limited_company_registration from './components/PopularServices/Private_limited_company_registration';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import RefundPolicy from './components/RefundPolicy';
import AboutUs from './components/AboutUs';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import GstRgistration from './components/Gst/GstRgistration';
import GstFiling from './components/Gst/GstFiling';
import PaymentCheckout from './components/PaymentCheckout';
import Login from './components/Authentication/Login';
import SignupPage from './components/Authentication/SignupPage';

import Elearning from './components/Elearning';
import Schemes from './components/Schemes';
import Districtservices from './components/Districtservices';
import PaymentCheckoutElearning from './components/PaymentCheckoutElearning';
import Elearningcontent from './components/Elearningcontent';
import SapElearningContent from './components/SapElearningContent';
import Faq from './components/Faq';
import MsOfficecontent from './components/MsOfficecontent';
import CambridgeElearningcontent from './components/CambridgeElearningcontent';
import EFilingElearningContent from './components/EbillingElearningcontent';
import FormPayment from './components/formpayment';





function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: 'ease-out-cubic',
    });
  }, []);

  useDocTitle('PG Network');

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact"  element={<ContactPage1 />} />
          <Route path="/get-demo" element={<DemoProduct />} />
          <Route path="/INCOMETAXFILING" element={<INCOMETAXFILING />} />
          <Route path="/ISOCertification" element={<ISOCertification />} />
          <Route path="/msme_registration" element={<Msme_registration />} />
          <Route path="/privateCompanyregistration" element={<Private_limited_company_registration />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termpolicy" element={<TermsOfUse />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/Aboutus" element={<AboutUs />} />
          <Route path="/GstRegistration" element={<GstRgistration/>} />
          <Route path="/GstFiling" element={<GstFiling/>} />
          <Route path="/PaymentCheckOut" element={<PaymentCheckout/>} />
          <Route path="/Login" element={<Login/>} />
          <Route path="/Singup" element={<SignupPage/>} />
          <Route path="/Edistrictservices" element={<Districtservices/>} />
          <Route path="/Elearning" element={<Elearning/>} />
          <Route path="/Elearningcontent" element={<Elearningcontent/>} />
          <Route path="/Schemes" element={<Schemes/>} />
          <Route path="/PaymentCheckoutElearning" element={<PaymentCheckoutElearning/>} />
          <Route path="/SapElearning" element={<SapElearningContent/>} />
          <Route path="/ElearningFaq" element={<Faq/>} />
          <Route path="/MsofficeElearning" element={<MsOfficecontent/>} />
          <Route path="/CambridgeElearning" element={<CambridgeElearningcontent/>} />
          <Route path="/eBILLING" element={<EFilingElearningContent/>} />
          <Route path="/sucesscheckout" element={<FormPayment/>} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
