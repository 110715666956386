import React from 'react'
import { Link } from 'react-router-dom';
import NavBar from '../Navbar/NavBar';
import ScrollButton from '../goTop';

export default function ISOCertification() {
  return (
    <div>
          <NavBar/>
      <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>
            
            <div className="absolute inset-0 "></div>

            
            <div className="relative flex flex-col items-center justify-center h-full text-center text-white">
              
                <div className="px-6 md:px-12 lg:px-24 xl:px-32">
                    <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">ISO Certification in India
                    </h1>
                    <p className="text-xl md:text-2xl lg:text-xl mb-6">Apply for ISO Certification and Registration in India with Lowest Cost.
                    ​Start Just at 1499/- INR</p>
                    <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                        <Link
                            to="/contact"
                            className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                        >
                            Book Now
                            <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>

            
        </div>
        <ScrollButton/>
    </div>
  )
}
