import React from 'react'
import NavBar from '../Navbar/NavBar'
import Contact from '../../pages/Contact';
import { Link } from 'react-router-dom'

import Footer from '../Footer'
import { Link as ScrollLink } from 'react-scroll';

function GstRgistration() {
    return (
        <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>

            <div className="absolute inset-0 "></div>
            <NavBar />

            <div className="relative flex flex-col items-center justify-center h-full text-center text-white">

                <div className="px-6 md:px-12 lg:px-24 xl:px-32">
                    <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">
                        GST Registration – New GST Registration Online
                    </h1>
                    <p className="text-xl md:text-2xl lg:text-xl mb-6">India’s Only Govt Certified Company “MyOnlineCA” Helps on GST Registration | New GST Registration Online
                        Start Only From 999/-INR with Free DIY Legal Agreements</p>
                    <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                        <ScrollLink
                            to="bookus"  // Replace with the ID of the section you want to scroll to
                            spy={true}
                            smooth={true}
                            offset={-70} // Adjust this value as per your header height
                            duration={500}
                            className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"

                        >
                            GET PROPOSAL
                            <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </ScrollLink>
                    </div>

                </div>
            </div>
            <div id='bookus'>
                <Contact />
            </div>
            <div className="px-16">
                <div className="max-w-full mx-auto bg-white  ">
                    <div>
                        <h2 className="text-3xl font-bold mb-4">What is GST Registration ?</h2>
                        <p className="mb-4">GST Registration introduced in India from July 2017. As per the act its a mixture of indirect tax like VAT & Service tax for the same. It is required when your turnover or sales cross more than 40 lakh rupees in a year ( 10 lakh for NE * Hill States) as per the latest amendment from 1st April 2019.</p>
                        <p className="mb-4">For some specific business, it is mandatory registration without crossing the turnover limit. For ex, if you are running an e-commerce business then you have to require the GST Registration from the starting of business. We at MyOnlineCA helps you on GST Registration Online which takes only 2-3 working days and got the verified GST Certification with login details in your Email Inbox.</p>
                    </div>
                </div>


                <div className="max-w-full mx-auto px-4 0 py-8">
                    <h1 className="text-3xl font-bold text-center mb-8">Who is required New GST Registration
                    </h1>
                    <ol className="pr-6">

                        <li><b>  #1 Previous Law Converted Taxpayer</b> – if any individual or companies are registered under the previous tax law like service tax or VAT or cst etc then  they have required the New GST Registration.                                                                           </li>
                        <li> <b> #2 Turnover basis</b> – if your business sales or turnover cross more than 40 lakh rupees in a year then its required the GST Registration. For some state like North-Eastern States, J&K, Himachal Pradesh, and Uttarakhand the limit is only 10 lakh rupees in a year.</li>
                        <li> <b> #3 Event or Exhibition </b>– In the GST Act its called the casual taxpayer. If they don’t have any permanent place of business then they can apply for the casual taxpayer under the GST Registration. It’s valid for a maximum of 90 days (3 months).</li>
                        <li> <b> #4 Non-Resident Indian (NRI)</b> – if you are a non-resident person of India or handling the business of NRI in India then it’s required to apply for the GST Registration Online.</li>
                        <li> <b> #5 The agent of Suppliers or Input tax Distributor</b> – if you are an input tax service distributor then it’s required to apply for the GST Registration to carry forward the benefit of an input tax credit under GST Law.</li>
                        <li> <b> #6 Reverse Charge Mechanism</b> – Under GST Law there is term reverse charge mechanism so if you fall under that category then its required the GST registration.</li>
                        <li> <b> #7 E-commerce Sellers</b> – if you are an E-commerce Sellers of the leading aggregator portal like Flipkart or Amazon then yes you need the GST Registration.</li>
                        <li> <b> #8 E-Commerce Aggregator Portal</b> – if you are thinking to start your own E-commerce business then for ECommerce Business Registration you need GST Registration for the same.</li>
                        <li> <b> #9 Outside India Online Portal</b> – if you are software as a service company and providing information & database access from outside India to Indian visitors then it’s required to register under the GST.</li>
                    </ol>


                    <div className=''>
                        <div className="mb-8">
                            <br />

                            <h2 className="text-3xl font-bold ">How GST Registration Online can be done. </h2>
                            <br />
                            <br />
                            <br />
                            <ol className="pr-6">

                                <li> 1. First of all, just log in on GST official Portal.                                                                       </li>
                                <li> 2. Fill up the Part A of GST Registration Form-1 by the Professional.</li>
                                <li> 3. You will get received the reference number via email and mobile number.</li>
                                <li> 4. Fill Up the second part of the registration form and upload the below documents as per the business entity.</li>
                                <li> 5. Finally, a Certificate of Registration has been issued by the Government of India once your application is approved.</li>
                            </ol>
                            <br />
                            <p>But Remember you always need a Professional like CA/GST Consultant to Choose the Right AO Code & Proper HSC Code for your registration with Proper Documents. so Always take services from Certified GST Consultant like myonlineca which helps you to get done GST Registration Online</p>
                        </div>
                    </div>
                    <div className="relative" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>
                        <div className="max-w-7xl  mx-auto a ">
                            <div>
                                <br />
                                <br />
                                <h4 className="text-3xl text-white font-bold mt-6 mb-2">GST Registration Package @ 999/- INR
                                </h4>

                            </div>


                            <ol className="pr-6 text-white">

                                <li> 1. First of all, just log in on GST official Portal.                                                                       </li>
                                <li> 2. Fill up the Part A of GST Registration Form-1 by the Professional.</li>
                                <li> 3. You will get received the reference number via email and mobile number.</li>
                                <li> 4. Fill Up the second part of the registration form and upload the below documents as per the business entity.</li>
                                <li> 5. Finally, a Certificate of Registration has been issued by the Government of India once your application is approved.</li>
                            </ol>

                            <br />
                            <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                                <ScrollLink
                                    to="bookus"  // Replace with the ID of the section you want to scroll to
                                    spy={true}
                                    smooth={true}
                                    offset={-70} // Adjust this value as per your header height
                                    duration={500}
                                    className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                                >
                                    Get Booking
                                    <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </ScrollLink>
                            </div>


                        </div>

                    </div>
                    <div className="container mx-auto p-6">

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">Features of GST Registration</h2>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">The concept of New GST Registration – A mixture of various taxes like VAT/CST, Service tax, Excise duty, Entertainment tax, etc., introduced in India in July 2017.</li>
                                <li className="mb-2">GST Registration Limit – Required if your business turnover or sales cross more than ₹40 lakh in a year, except for certain businesses where it's required from the start.</li>
                                <li className="mb-2">GST Registration Tax Rates – Vary from 0% to 28% based on the product or service.</li>
                                <li className="mb-2">GST Input Tax Credit Scheme – Only GSTIN holders can avail of the input tax credit, allowing them to claim tax paid during the purchase of products or services.</li>
                                <li className="mb-2">GST Return Filing Concept – GSTIN holders must file 3 GST returns monthly or quarterly and pay taxes every month.</li>
                                <li className="mb-2">GST Registration under Composition Scheme – Available for businesses with less than ₹1 crore turnover, allowing them to pay taxes from 1% to 5% per month without claiming input tax credit.</li>
                            </ul>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">Benefits of GST Registration</h2>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">More robust compared to competitors – Provides valid legal entity registration.</li>
                                <li className="mb-2">Take Input Tax Credit – Available for GSTIN holders during the purchase of goods or services.</li>
                                <li className="mb-2">Sell anywhere in India – No interstate supply restrictions.</li>
                                <li className="mb-2">Open a current business bank account – Easily open a current account with a GST Registration Certificate.</li>
                                <li className="mb-2">Sell online – Easily sell on e-commerce portals like Amazon or Flipkart with a GSTIN Registration Number.</li>
                                <li className="mb-2">Bid for government projects – Required for contractors applying for government tenders.</li>
                                <li className="mb-2">Accept big projects from MNCs – Required for dealing with MNCs.</li>
                                <li className="mb-2">Increase customer trust – Valid GSTIN tax invoices increase customer trust and brand value.</li>
                            </ul>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">GST Registration Number</h2>
                            <p>GST Registration Number is a 15-digit unique tax identification number assigned to each firm, company, or individual. It’s like a PAN number and is based on the PAN number and state code.</p>
                            <p>For example: 08AAKCM0506E1ZT</p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">Documents Required for GST Registration</h2>
                            <ul className="list-disc pl-6">
                                <li className="mb-2"><strong>Sole Proprietorship Firm:</strong>
                                    <ul className="list-disc pl-6">
                                        <li>PAN Card and Address Proof of the proprietor.</li>
                                        <li>Any other firm registration certificate like MSME Registration etc.</li>
                                    </ul>
                                </li>
                                <li className="mb-2"><strong>Partnership Firm:</strong>
                                    <ul className="list-disc pl-6">
                                        <li>PAN card of the Partnership Firm with the deed.</li>
                                        <li>Partnership registration certificate & partners' ID & address proof.</li>
                                    </ul>
                                </li>
                                <li className="mb-2"><strong>Private Limited Company or One Person Company:</strong>
                                    <ul className="list-disc pl-6">
                                        <li>PAN Card of the Company, COI, MOA & AOA of Company.</li>
                                        <li>Board Resolution with Directors' ID & Address Proof.</li>
                                    </ul>
                                </li>
                                <li className="mb-2"><strong>LLP (Limited Liability Partnership):</strong>
                                    <ul className="list-disc pl-6">
                                        <li>COI & PAN Card of Company.</li>
                                        <li>Deed + Partners' ID & Address Proof.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Additionally, a bank statement copy or passbook, and proper business place electricity bill copy with NOC or sale deed are required.</p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">GST Registration Online Process</h2>
                            <ol className="list-decimal pl-6">
                                <li className="mb-2">Place a request with your basic details.</li>
                                <li className="mb-2">Make the online payment via cards/net banking/UPI.</li>
                                <li className="mb-2">Get connected with a dedicated CA to help with the entire GST Registration.</li>
                                <li className="mb-2">Receive the ARN number to check your GST Registration Status.</li>
                            </ol>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">Penalty for GST Registration</h2>
                            <p>If you are liable to register under GST but fail to do so, or if you make short payments, the penalty is 10% of the total tax amount, subject to a minimum of ₹10,000. The penalty can be 100% of the tax amount for deliberate tax evasion.</p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-2xl font-semibold mb-4">Compliance after GST Registration</h2>
                            <p>After GST Registration, each assessee must file GST returns as per compliance. Regular scheme registrants must file monthly returns, while composition dealers file quarterly with conditions.</p>
                        </section>
                    </div>
                    <h2 className="text-2xl font-semibold mb-4">FAQ on GST Registration</h2>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">What is the Full Form of GSTIN Number</h2>
                        <p> GSTIN is referring to Goods and service tax identification number which we already discussed above the 15 digit GSTIN Number.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">When GST Registration is required mandatory for any type of business</h2>
                        <p>Basically, it’s required only when your sales cross more than 40 lakh rupees in a year but some business like B2B or E-commerce or Trader’s which is required interstate sales needed GST Registration from the Starting of business.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">Do I need the Multiple GSTIN For my Business</h2>
                        <p>If your business is dealing into the products or services both or different type of the products or services then you can easily mention in your GST Certificate about these services or products. So it not required the multiple GSTIN Number for your business. But if you are setting up your business in a different state & supply the goods or services from that different location then yes it’s required the multiple GST Registration for the same.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">Can we apply GST on our Firm or Brand Name</h2>
                        <p>Yes, GST Registration required a one legal entity name, so if you are registered as proprietorship firm then yes you can provide your any firm name. There are no name guidelines on the firm name while applying for the GST registration.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">Do I need Commercial Address for the GST Registration</h2>
                        <p>No, if you are running your business from home then the residential address can be registered with the GST. It is required only the proof of address like electricity bill copy with the NOC or sale deed or rent agreement copy etc.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">Do I need a bank account for the GST Registration</h2>
                        <p>Yes, you need a personal saving bank account or current bank account. If you are new business and have only the personal saving bank account then you can provide the same & after the GST Registration, you can apply for the new current bank account on the basis of GST Certificate.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">What action required after the GST Registration</h2>
                        <p>Once you applied for the GST Registration, after that each month you have to raise the GST Invoice to your customer or client & charge the proper GST Amount from them. At the end of the month you have to pay the taxes online for the same.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">What if I am selling across India then required multiple GST</h2>
                        <p>No, you need only 1 GST Registration for the same. With the GST Registration, you can sell interstate for the same.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">How many times takes for the GST Registration</h2>
                        <p>Normally, it always depends on Government approval. So it takes min 4-5 days for the GST Registration.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">There is any rejection during the GST Registration</h2>
                        <p>Yes, if there are not proper documents then GST Registration is subject to govt approval. If govt officer denied the application then you have to file the fresh new GST registration.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">If I have already VAT/CST or Service tax then do I need change into GST</h2>
                        <p>Yes, you have to migrate into the GST Registration for the same. For that simply login your old respective portal where you will get the provisional user id after that simply create your GSTIN User id on the basis of Provisional user id. After that, the procedure is the same which is mentioned above for GST Registration.</p>
                    </section>

                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">What is GST Composition Scheme under GST Law</h2>
                        <p>If your turnover is less than 1.5 Cr except for some state like North-Eastern states and Himachal Pradesh where limit if 75 lakh rupees. ( Updated from 1st April 2019) So you can opt for the Composition Scheme during the GST Registration.</p>
                    </section>

                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">All the business less than 1.5 cr turnover eligible for the Composition Scheme</h2>
                        <p>No, all the business are not eligible even they have less than 1.5 Cr. turnover For ex – – All services business except the restaurant. – If business deals interstate – If annual turnover is more than 1.5 Cr – E-commerce sellers or Importer of Goods – Supplier of non-taxable items etc.</p>
                    </section>


                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">How to Track GST Registration Status</h2>
                        <p>Once your application has been filed, after that you will get the ARN Number which is treated as acknowledgement number. So you can easily track your application status by ARN Number.</p>
                    </section>


                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">What documents required for Place of Business proof</h2>
                        <p>It’s always required electricity bill copy even if have rented a place and NOC From the landlord. NOC Format will be shared by myonlineca during the registration process.</p>
                    </section>

                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">How I have to send my documents to myonlineca for GST Registration</h2>
                        <p>Simply we accept documents secure over email or WhatsApp. Don’t worry about your documents, we have always 100% confidential policy and after the work, your documents have been deleted from the system.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">How to Choose HSN or SAC Code during GST Registration</h2>
                        <p>HSN or SAC Code refers to Goods and Services code. So at myonlineca, our CA helps you to choose the right HSN or SAC Code after getting details about your business.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">When Digital Signature is required for the GST Registration</h2>
                        <p>DSC is required only in case of the private limited company or OPC or LLP etc. for the Proprietorship Firm or partnership firm, it’s not required the DSC.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">Do I have to visit the GST Department with my paper for any verification</h2>
                        <p>No, MyOnlineCA takes care of each thing. So you have not required to visit any kind of govt office etc. simply register on our website and get done your GST Registration at fingertips.</p>
                    </section>
                    <section className="mb-6  ">
                        <h2 className="text-2xl font-semibold mb-4">Why I Choose MyOnlineCA for my GST Registration</h2>
                        <p>We have a solid 5 reasons to Choose myonlineca for the GST Registration:- a) Trusted by 25k+ companies & small business across India. b) Govt Startup India Certified Portal c) Issued more than 10K+ GSTIN Numbers d) Help in GST Return Filing Compliance etc.</p>
                    </section>


                </div>

            </div>
            <Footer />
        </div>



    )
}

export default GstRgistration