// src/components/Elearning.js
import React from 'react';
import Elearningimage from '../images/Elearning.jpg'; // Adjust the path as needed
import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Login from './Authentication/Login';
import { Link as ScrollLink, Element } from 'react-scroll';
import ScrollButton from './goTop';

const Elearning = () => {
    return (
        <div>
            <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>
                <NavBar />
                <div className="relative flex flex-col items-center justify-center h-full text-center text-white">
                    <div className="px-6 md:px-12 lg:px-24 xl:px-32">
                        <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">Up skill | Reskill | Cross skill</h1>
                        <p className="text-xl md:text-2xl lg:text-xl mb-6">Tech Innovator in Commerce. Commerce technology revolutionizing the industry from education to application.</p>
                        <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                        <ScrollLink // Use ScrollLink from react-scroll
                            to="login" // ID of the element to scroll to
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="text-white bg-red-900 hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                        >
                                Get Started
                                <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                                </ScrollLink>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-white text-black px-6 py-8 shadow-xl flex justify-between items-center">
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Get Started with P.G Network</h2>
                        <p className="text-lg mb-4">India's top-class Accounting & Tax Consultant, trusted by more than 10000+ happy clients & 1000+ social reviews</p>
                    </div>
                    <div>
                        <ScrollLink // Use ScrollLink from react-scroll
                            to="login" // ID of the element to scroll to
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="text-white bg-red-900 hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                        >
                            Get Started
                            <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </ScrollLink>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between mb-12 p-6  ">
                <div className="lg:w-1/2 flex justify-center items-center">
                    <img alt="card img" className="rounded-lg shadow-lg w-full lg:max-w-md h-auto" src={Elearningimage} />
                </div>
                <div className="w-full md:w-1/2 mt-6 md:mt-0 text-blacl md:pl-10">
                    <h2 className="text-4xl font-bold mb-4">A catalyst for the evolutionary phases of 21st century commerce.</h2>
                    <p className="text-xl mb-6">Join us to unlock the full potential of commerce technology. Whether you want to upskill, reskill, or cross-skill, we provide the resources and support you need to succeed.</p>
                    <Link
                        to="/Aboutus"
                        className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full"
                    >
                        Learn More
                        <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </Link>
                </div>
            </div>
            <Element id="login" name="login"> {/* Use Element from react-scroll for the Login component */}
                <Login  />
            </Element>
            <Footer />
            <ScrollButton/>
        </div>
    );
};

export default Elearning;
