import React, { useEffect, useState } from 'react';
import { FaUser, FaLayerGroup, FaAdjust, FaEye, FaShieldAlt, FaTimes } from 'react-icons/fa'; // Import necessary icons
import img from '../images/Sap.jpg';
import img2 from '../images/cambridge-university-press.png';
import img4 from '../images/Msoffice.png';
import img5 from '../images/E-BillingLogo2.jpg';
import NavBar from './Navbar/NavBar';
import { useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom';

const Elearningcontent = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const token = localStorage.getItem('token');
  const tokenExpiration = localStorage.getItem('tokenExpiration');
  useEffect(() => {
    // const tokenRegister = localStorage.getItem('tokenLogin');
    const tokenRegister = "sdfjkds";
    // Function to fetch user data
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://pgnetworkbackend-production.up.railway.app/api/users/${tokenRegister}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const userData = await response.json();
        setUserName(userData.name); // Update state with user's name
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, []);



  useEffect(() => {
    const token = localStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    if (token && tokenExpiration) {
      const currentTime = new Date().getTime();
      if (currentTime < tokenExpiration) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        // Show alert and navigate to Elearning page if user is not logged in
        Notiflix.Report.failure(
          'Unauthorized Access',
          'Please login to access this page.',
          'Okay',
          () => {
            // Optional callback on Okay button click
            navigate('/Elearning');
          }
        );
      }
    }
  }, [tokenExpiration, token, navigate]);


  const pdfUrls = {
    SAP: '/path/to/sap.pdf',
    'Cambridge University Press': '/path/to/cambridge.pdf',
    'Microsoft Office': '/path/to/microsoft.pdf',
    Ebilling: '/path/to/ebilling.pdf'
  };

  // State to manage which PDF to display
  const [selectedPdf, setSelectedPdf] = useState(null);

  // Function to handle "View Details" button click
  const handleViewDetails = (course) => {
    setSelectedPdf(pdfUrls[course]);
  };

  // Function to close PDF viewer
  const closePdfViewer = () => {
    setSelectedPdf(null);
  };
  if (!isLoggedIn) {
    // Render a message or redirect to login page if user is not logged in
    return <p>Please login to access services.</p>;
  }
  return (
    <div className="py-12" id="services">
      {/* <NavBar /> */}

      {/* User Information Section */}
      <div className="flex items-center justify-center space-x-4 mb-4">
        <FaUser className="text-blue-500 text-4xl" />
        <span className="text-lg font-semibold">{userName}</span>
      </div>

      <section data-aos="zoom-in-down ">
        <div className="mt-4 py-4">
          <div className="bg-white shadow-2xl w-15 h-10">
            {/* Icon and Demo Name */}
            <FaLayerGroup className="text-blue-500 inline-block mx-2" />
            <span className="font-bold text-lg">E-Learning Courses</span>
          </div>
          <h2 className="text-center text-3xl text-custom-blue shadow-2xl font-bold mt-4">My Courses</h2>
          <div className='flex justify-center'>
            <div className='w-24 border-b-4 border-custom-blue'></div>
          </div>
          <div className="mt-4 mx-4 bg-blue-400 rounded-lg shadow-2xl w-30 h-20">
            {/* View Details Section */}
            <div className="flex items-center justify-center h-full">

            </div>
          </div>
        </div>

        <div className="px-6" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* SAP */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
              <img alt="SAP" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
              <div className="p-4">
                <h2 className="font-semibold my-4 text-xl text-center">SAP</h2>
                <p className="text-md font-medium">
                  SAP, at the forefront of today's technology revolution, is the leading provider of enterprise applications worldwide. Its comprehensive suite of software and services empowers organizations to streamline operations, enhance decision-making, and drive innovation.
                </p>
                <Link to="/SapElearning" className="bg-white text-blue-500 px-4 py-2 rounded-lg shadow-lg hover:bg-blue-500 hover:text-white transition duration-300 block mt-auto">View Details</Link>
              </div>
            </div>

            {/* Cambridge University Press */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
              <img alt="Cambridge University Press" className="rounded-t group-hover:scale-[1.15] transition duration-1000 w-full    ease-in-out" src={img2} />
              <div className="p-4">
                <h2 className="font-semibold my-4 text-xl text-center">Cambridge University Press</h2>
                <p className="text-md font-medium">
                  Unlock your potential with Cambridge University Press' Soft Skills & Employability Skills Course. Designed to equip learners with essential interpersonal and professional skills, this course prepares you for success in today's competitive job market.
                </p>
                <Link to="/CambridgeElearning" className="bg-white text-blue-500 px-4 py-2 rounded-lg shadow-lg hover:bg-blue-500 hover:text-white transition duration-300 block mt-auto">View Details</Link>
              </div>
            </div>

            {/* Microsoft Office */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
              <img alt="Microsoft Office" className="rounded-t group-hover:scale-[1.15] transition duration-1000 w-full h-100 ease-in-out" src={img4} />
              <div className="p-4">
                <h2 className="font-semibold my-4 text-xl text-center">Microsoft Office</h2>
                <p className="text-md font-medium">
                  Earn your Microsoft Office Specialist (MOS) certification and stand out in today's digital landscape. Master essential productivity tools like Word, Excel, and PowerPoint to boost your efficiency and advance your career.
                </p>
                <Link to="/MsofficeElearning" className="bg-white text-blue-500 px-4 py-2 rounded-lg shadow-lg hover:bg-blue-500 hover:text-white transition duration-300 block mt-auto">View Details</Link>
              </div>
            </div>

            {/* Ebilling */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-4 group">
              <img alt="Ebilling" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img5} />
              <div className="p-4">
                <h2 className="font-semibold my-4 text-2xl text-center">Ebilling</h2>
                <p className="text-md font-medium">
                  Embrace digital literacy with eFiling, an essential training program for mastering electronic documentation and compliance. Learn the intricacies of eFiling, from income tax to GST, and gain the skills needed to navigate modern statutory requirements confidently.
                </p>
                <Link to="/EbillingElearning" className="bg-white text-blue-500 px-4 py-2 rounded-lg shadow-lg hover:bg-blue-500 hover:text-white transition duration-300 block mt-auto">View Details</Link>
              </div>
            </div>
          </div>
        </div>


        {/* FAQ, Feedback, Help Section */}
        <div className="mt-12 px-12">
          <div className="flex justify-center space-x-8">
            <div>
              <FaAdjust className="text-blue-500 text-4xl mb-2" />

              <Link to="/ElearningFaq"><h3 className="text-xl font-bold">FAQ</h3></Link>
              <p className="text-sm">Answers to common questions.</p>
            </div>
            <div>
              <FaEye className="text-blue-500 text-4xl mb-2" />
              <h3 className="text-xl font-bold">Feedback</h3>
              {/* <iframe src={selectedPdf} className="w-full h-screen"></iframe> */}
              <p className="text-sm">Provide feedback on your experience.</p>
            </div>
            <div>
              <FaShieldAlt className="text-blue-500 text-4xl mb-2" />
              <Link to="/Contact"> <h3 className="text-xl font-bold">Help</h3></Link>
              <p className="text-sm">Get assistance with any issues.</p>
            </div>
          </div>
        </div>
      </section>

      {/* PDF Viewer Modal or Dropdown */}
      {selectedPdf && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <button onClick={closePdfViewer} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
              <FaTimes className="text-xl" />
            </button>
            <iframe src={selectedPdf} className="w-full h-screen"></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default Elearningcontent;
