import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FaChevronDown, FaAngleDown, FaFile, FaCertificate, FaBook, FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';

function NavBar() {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const closeDropdowns = () => {
    setOpenDropdown(null);
  };

  return (
    <nav className="flex items-center justify-between ml-3 space-x-4 from-gray-100 to-gray-200 shadow-none">
      <ul className="flex flex-wrap top-0 space-x-4">
        <li className="mb-2 md:mb-0">
          <Link smooth to="/IncomeTaxFiling" className="text-m font-semibold text-gray-800 hover:text-gray-900 transition duration-300  rounded-md px-4 py-2 flex items-center">
            <FaFile className="mr-2" /> Income Tax Filing
          </Link>
        </li>
        <li className="mb-2 md:mb-0">
          <Link smooth to="/EdistrictServices" className="text-sm font-semibold text-gray-800 hover:text-gray-900 transition duration-300  rounded-md px-4 py-2 flex items-center">
            <FaFile className="mr-2" /> E - District Services
          </Link>
        </li>
        <li className="relative mb-2 md:mb-0">
          <Dropdown show={openDropdown === 'Popular'}>
            <Dropdown.Toggle
              id="popular-dropdown"
              className="text-sm font-semibold text-gray-800 hover:text-gray-900 transition duration-300  rounded-md px-4 py-2 flex items-center"
              onClick={() => toggleDropdown('Popular')}
            >
              <FaBook className="mr-2" /> Popular Services
              <FaAngleDown className="ml-2" />
            </Dropdown.Toggle>
            {openDropdown === 'Popular' && (
              <Dropdown.Menu
                className="dropdown-menu absolute top-full right-0 min-w-48 bg-purple-600 shadow-lg rounded-md mt-2"
                show={openDropdown === 'Popular'}
                onClick={closeDropdowns}
              >
                <Dropdown.Item as={Link} smooth to="/IncomeTaxFiling" className="block px-4 py-2 text-white hover:bg-purple-700">
                  ITR Filing Services
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/ISOCertification" className="block px-4 py-2 text-white hover:bg-purple-700">
                  ISO Certification
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/msme_registration" className="block px-4 py-2 text-white hover:bg-purple-700">
                  MSME Registration
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/PrivateCompanyRegistration" className="block px-4 py-2 text-white hover:bg-purple-700">
                  Company Registration
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/contact#contact" className="block px-4 py-2 text-white hover:bg-purple-700">
                  Firm Registration
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/contact#contact" className="block px-4 py-2 text-white hover:bg-purple-700">
                  FSSAI License
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/contact#contact" className="block px-4 py-2 text-white hover:bg-purple-700">
                  Trademark Registration
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/contact#contact" className="block px-4 py-2 text-white hover:bg-purple-700">
                  Pan Card
                </Dropdown.Item>
                <Dropdown.Item as={Link} smooth to="/contact#contact" className="block px-4 py-2 text-white hover:bg-purple-700">
                  Instant Pan Card
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </li>
        {/*   */}
        <li className="mb-2 md:mb-0">
          <Link smooth to="/contact#contact" className="flex items-center text-sm font-semibold text-gray-800 hover:text-gray-900 transition duration-300  rounded-md px-4 py-2">
            Book Consultancy
          </Link>
        </li>
        <li className="mb-2 md:mb-0">
          <Link smooth to="/Aboutus" className="flex items-center text-sm font-semibold text-gray-800 hover:text-gray-900 transition duration-300  rounded-md px-4 py-2">
            About Us
          </Link>
        </li>
        <li className="mb-2 md:mb-0">
          <Link smooth to="/PaymentCheckOut" className="flex items-center text-sm font-semibold text-gray-800 hover:text-gray-900 transition duration-300  rounded-md px-4 py-2">
            Payment Checkout
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
