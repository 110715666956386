import React from 'react';
import { Link } from 'react-router-dom';
import { FaBuilding, FaFileAlt, FaBalanceScale, FaFileInvoice, FaGavel, FaRegAddressBook, FaCcVisa, FaCcMastercard, FaCcPaypal, FaCcDiscover, FaCcAmex, FaMapMarkerAlt, FaPhoneAlt, FaGlobe, FaMailchimp } from 'react-icons/fa';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const openContactForm = () => {
        scrollToTop();
        console.log('Opening contact form...');
    };

    return (
        <footer className="bg-gradient-to-r from-purple-600 to-blue-600 text-white ">

            <div className="flex justify-center items-center space-x-4">

                <div className="  overflow-hidden rounded-sm flex items-center justify-center">
                    <FaCcVisa className="text-white text-3xl relative z-10 transition-transform group-hover:rotate-6" />
                </div>
                <div className=" justify-center">
                    <FaCcMastercard className="text-white text-3xl relative z-10 transition-transform group-hover:rotate-6" />
                </div>
                <div className=" justify-center">

                    <FaCcPaypal className="text-white text-3xl relative z-10 transition-transform group-hover:rotate-6" />
                </div>


                <div className=" flex items-center justify-center">

                    <FaCcDiscover className="text-white text-3xl relative z-10 transition-transform group-hover:rotate-6" />
                </div>


                <div className=" flex items-center justify-center">

                    <FaCcAmex className="text-white text-3xl relative z-10 transition-transform group-hover:rotate-6" />
                </div>
            </div>

            <div className="max-w-full mx-auto px-4 sm:px-6 border-t border-b py-8">


                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 py-8">
                    <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaBuilding className="mr-2" />
                            Business Registration
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/PrivateCompanyRegistration" className="hover:text-yellow-300" onClick={openContactForm}>Pvt Ltd Company Registration</Link></li>
                            <li><Link to="/contact#contact" className="hover:text-yellow-300" onClick={openContactForm}>OPC Registration</Link></li>
                            <li><Link to="/contact#contact" className="hover:text-yellow-300" onClick={openContactForm}>LLP Registration</Link></li>
                            <li><Link to="/contact#contact" className="hover:text-yellow-300" onClick={openContactForm}>Partnership Registration</Link></li>
                            <li><Link to="/contact#contact" className="hover:text-yellow-300" onClick={openContactForm}>Proprietorship Registration</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaFileAlt className="mr-2" />
                            GST Portal
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/GstRegistration" className="hover:text-yellow-300" onClick={openContactForm}>GST Registration</Link></li>
                            <li><Link to="/GstFiling" className="hover:text-yellow-300" onClick={openContactForm}>GST Return Filing</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaBalanceScale className="mr-2" />
                            Other Legal Registration
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/msme_registration" className="hover:text-yellow-300" onClick={openContactForm}>MSME Registration</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>IEC Code Registration</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>FSSAI Registration</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Shop Registration</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Gumasta License</Link></li>
                        </ul>
                    </div>
                    {/* <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaBalanceScale className="mr-2" />
                            E Learning
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/Elearning" className="hover:text-yellow-300" onClick={openContactForm}>Learning Catalogue</Link></li>
                            <li><Link to="/Elearning" className="hover:text-yellow-300" onClick={openContactForm}>Course Details</Link></li>
                            <li><Link to="/Elearning" className="hover:text-yellow-300" onClick={openContactForm}>Get Subscription</Link></li>
                            <li><Link to="/Elearning" className="hover:text-yellow-300" onClick={openContactForm}>Login and Sign Up Page</Link></li>
                            <li><Link to="/PaymentCheckOut" className="hover:text-yellow-300" onClick={openContactForm}>Payment Checkout</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Contact Now</Link></li>
                        </ul>
                    </div> */}
                    <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaFileInvoice className="mr-2" />
                            Annual Service
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Company Annual Filing</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>LLP Annual Filing</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Income Tax Return</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaGavel className="mr-2" />
                            IPR Services
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Trademark Registration</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Patent Registration</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaRegAddressBook className="mr-2" />
                            Payment Checkout
                        </h6>
                        <ul className="text-base space-y-2">
                            <li><Link to="/PaymentCheckOut" className="hover:text-yellow-300" onClick={openContactForm}>Pay Now</Link></li>
                            <li><Link to="/PaymentCheckOut" className="hover:text-yellow-300" onClick={openContactForm}>Quertly, Yearly and Learning Plan Payments</Link></li>
                            <li><Link to="/PaymentCheckOut" className="hover:text-yellow-300" onClick={openContactForm}>Other Payments</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-300" onClick={openContactForm}>Contact Now</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className="bg-gray-800 py-4">
                <div className="container mx-auto flex justify-center">
                    <div className="text-sm text-gray-400 flex items-center space-x-6">
                        <Link to="/privacypolicy" className="flex items-center text-gray-300 hover:text-white transition duration-300 ease-in-out">
                            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="currentColor" />
                                <path d="M13 7h-2v6h2V7zm0 8h-2v2h2v-2z" fill="currentColor" />
                            </svg>
                            Privacy Policy
                        </Link>
                        <Link to="/termpolicy" className="flex items-center text-gray-300 hover:text-white transition duration-300 ease-in-out">
                            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="currentColor" />
                                <path d="M13 7h-2v6h2V7zm0 8h-2v2h2v-2z" fill="currentColor" />
                            </svg>
                            Terms of Use
                        </Link>
                        <Link to="/refundpolicy" className="flex items-center text-gray-300 hover:text-white transition duration-300 ease-in-out">
                            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="currentColor" />
                                <path d="M13 7h-2v6h2V7zm0 8h-2v2h2v-2z" fill="currentColor" />
                            </svg>
                            Refund Policy
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
