import React, { useState } from 'react';
import NavBar from './Navbar/NavBar';
import { FiCheckCircle } from 'react-icons/fi'; // Import necessary icons from react-icons
import { FaTimes } from 'react-icons/fa'; // Import close icon

// Import your PDF, XLSX, and DOCX files dynamically based on the directory content
import TableXLSX from '../images/Msoffice/1.Table.xlsx';
import FormulasXLSX from '../images/Msoffice/2.FORMULAS.xlsx';
import ConsolidationXLSX from '../images/Msoffice/4.CONSOLIDATION.xlsx';
import ExcelTextbookPDF from '../images/Msoffice/EXCEL_16_Textbook.pdf';
import PowerPointPDF from '../images/Msoffice/MOAC_Powerpoint_2016.pdf';
import WordStudyGuideDOCX from '../images/Msoffice/MOS_2016_Study_Guide_for_Microsoft_Word.docx';

function MsOfficecontent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [hideNavbarAndFooter, setHideNavbarAndFooter] = useState(false);

  // Function to open the file viewer modal
  const openFileViewer = (filePath) => {
    setSelectedFile(filePath);
    setHideNavbarAndFooter(true); // Hide navbar and footer when opening file viewer
  };

  // Function to close the file viewer modal
  const closeFileViewer = () => {
    setSelectedFile(null);
    setZoomLevel(1); // Reset zoom level when closing
    setHideNavbarAndFooter(false); // Show navbar and footer when closing file viewer
  };

  // Function to zoom in the file
  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.2);
  };

  // Function to zoom out the file
  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };

  return (
    <div>
      {!hideNavbarAndFooter && <NavBar />} {/* Render navbar if not hiding */}
      
      <div className="max-w-4xl mt-40 mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">Microsoft Office (Word, PowerPoint & Excel)</h1>
        <p className="mb-4">
          Microsoft Office (MOS) certification validates the desktop application and is helping students around the globe as they pursue employment.
          This knowledge base comes directly from Microsoft, architect of the Office 2016 system and creator of the Microsoft Office Specialist (MOS) exams,
          it is made sure to receive the topical coverage that is most relevant to students’ personal and professional success.
        </p>
        <p className="mb-4">
          Microsoft’s direct participation not only assures you that MOAC textbook content is accurate and current; it also means that students will receive the best
          instruction possible to enable their success on certification exams and in the workplace.
        </p>
        <p className="mb-4">
          The MOAC courseware for Microsoft Office 2016 system is designed to cover all the learning objectives for that MOS exam,
          which is referred to as its “objective domain.”
        </p>
        <p className="mb-4">
          The course features have been developed specifically for Microsoft Official Academic Course programs.
        </p>
        <h2 className="text-2xl font-bold mb-2">Learning Materials</h2>
        <p className="mb-4">
          Learning materials for this course will be provided in 3 parts. You can access the content for this course from the below options.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          <button 
            onClick={() => openFileViewer(WordStudyGuideDOCX)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            MS Word
          </button>
          <button 
            onClick={() => openFileViewer(PowerPointPDF)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            MS PowerPoint
          </button>
          <button 
            onClick={() => openFileViewer(ExcelTextbookPDF)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            MS Excel
          </button>
          <button 
            onClick={() => openFileViewer(TableXLSX)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            Question Bank
          </button>
          <button 
            onClick={() => openFileViewer(FormulasXLSX)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            Excel Practice Sheets
          </button>
        </div>

        {/* File Viewer Modal */}
        {selectedFile && (
          <div className="fixed top-0 left-0 mb-10 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg shadow-lg relative">
              <button onClick={closeFileViewer} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
                <FaTimes className="text-xl" />
              </button>
              <div className="relative w-screen h-screen">
                {selectedFile.endsWith('.pdf') ? (
                  <embed
                    src={selectedFile}
                    type="application/pdf"
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                  />
                ) : (
                  <iframe
                    src={selectedFile}
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                  />
                )}
              </div>
              <div className="flex justify-center mt-2">
                <button onClick={zoomIn} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2">Zoom In</button>
                <button onClick={zoomOut} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Zoom Out</button>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center mb-4">
          <FiCheckCircle className="text-green-500 mr-2" size={24} />
          <span className="text-lg">Successfully enrolled!</span>
        </div>
      </div>

      {!hideNavbarAndFooter && (
        <footer className="bg-gray-800 text-white text-center py-4"> {/* Render footer if not hiding */}
          &copy; 2024 Microsoft Office E-Learning Content. All rights reserved.
        </footer>
      )}
    </div>
  );
}

export default MsOfficecontent;
