import React from 'react'
import NavBar from '../Navbar/NavBar'

import { Link } from 'react-router-dom'

import Footer from '../Footer'
import ScrollButton from '../goTop'
import { FaBuilding, FaCity, FaIndustry } from 'react-icons/fa'

function Msme_registration() {
    return (
        <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>

            <div className="absolute inset-0 "></div>
            <NavBar />

            <div className="relative flex flex-col items-center justify-center h-full text-center text-white">

                <div className="px-6 md:px-12 lg:px-24 xl:px-32">
                    <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">
                        MSME Registration Online
                    </h1>
                    <p className="text-xl md:text-2xl lg:text-xl mb-6">Now Apply for MSME Registration Online anywhere in India with MyOnlineCA
                        Govt Recognized Portal. Issued 1 lakh+ certificate</p>
                    <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                        <Link
                            to="/contact"
                            className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                        >
                            GET PROPOSAL
                            <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="px-16">
                <div className="max-w-full mx-auto ">
                    <div>
                        <h2 className="text-3xl font-bold mb-4">What is MSME Registration?</h2>
                        <p className="mb-4">MSME Registration is playing very important role for the small and medium business across India.Numerous surveys show that country’s true economic growth reckoned not just on its large-scale industries and foreign investments but also on its small and medium level industries that also includes various cottage industries operating in India. By looking towards the developing country like India whose economy is basically dependent on agriculture and small-scale industries, applying for MSME registration or SSI Registration could be considered as its backbone.

                            In India, every state has its own tradition and culture which they follow and become eminent in that kind of industry. No doubt that India is a country with its huge stock of natural resources with intense climate variations, so promoting and working in such type of industries on a very large scale is very common among the traders.</p>
                        <p className="mb-4">So Ultimately MSME Registration are a certification which is issued by respective state government department to avail to benefits under the MSME Act. Now from the 2015 government modified the MSME or SSI Registration and its has been changed into the Udyog Aadhar Registration.</p>
                    </div>

                    <div>
                        <h2 className="text-3xl font-bold mt-6 mb-2">Why MSME Registration are Important for Small Business ?</h2>
                        <p className="mb-4">MSME registration is really important for developing the transparency and accountability of the above-mentioned industries, Government of India has basically categorized these industries which is known as MSME- Micro, Small and Medium Enterprises. MSME is actually an authorized body that helps in promoting these industries throughout the country with the help of officially legalized Act. One of the unique advantage of MSME registration is that it authorizes numerous incentives and incentives to industries included under MSMED Act. In order to subscribe under MSME, a MSME registration procedure is mandatory. Apart from the enterprises as mentioned above, some firms, or shop registration or public and private limited companies, LLPs are also eligible to apply for registration under MSME to avail its benefits.</p>
                    </div>






                    <div className="bg-gradient-to-r from-purple-600 to-indigo-500 p-6 rounded-lg shadow-lg" >
                        <div className="max-w-5xl mx-auto px-6 py-12 relative z-10">
                            <div className="">
                                <h2 className="text-3xl font-extrabold text-white mb-4 ">Classification under MSME Registration</h2>
                                <p className="text-lg text-white mb-6">
                                    Before applying for MSME registration, an industry owner should understand the classification criteria to ensure accurate categorization and to avoid technical complications. The Government of India has established the following classification rules for industries:
                                </p>

                                <div className="space-y-4 mb-6">
                                    <div className="bg-white text-gray-800 p-4 rounded-lg shadow-md flex items-start">
                                        <FaIndustry className="w-6 h-6 text-purple-500 mr-3" />
                                        <div>
                                            <p className="text-xl font-semibold text-black mb-2">Micro industries</p>
                                            <p className="text-black">In applying for registration of these kinds of industries your investment shall not exceed 25 lakhs for companies in the manufacturing sectors and 10 lakhs for the service sector.</p>
                                        </div>
                                    </div>

                                    <div className="bg-white text-gray-800 p-4 rounded-lg shadow-md flex items-start">
                                        <FaBuilding className="w-6 h-6 text-blue-500 mr-3" />
                                        <div>
                                            <p className="text-xl font-semibold text-black mb-2">Small industries</p>
                                            <p className="text-black">In small scale industries the investment shall not exceed 5 crores for companies in the manufacturing sectors and 2 crores for the service sector.</p>
                                        </div>
                                    </div>

                                    <div className="bg-white text-gray-800 p-4 rounded-lg shadow-md flex items-start">
                                        <FaCity className="w-6 h-6 text-green-500 mr-3" />
                                        <div>
                                            <p className="text-xl font-semibold text-black   mb-2">Medium industries</p>
                                            <p className="text-black">When applying for medium industries your investment shall not exceed 10 crores for companies in the manufacturing sectors and 5 crores for the service sector.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    <Link
                                        to="/contact"
                                        className="inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl text-white bg-black  transition duration-300 ease-in-out rounded-full shadow-lg"
                                    >
                                        Get Booking
                                        <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="max-w-full mx-auto px-4 0 py-8">
                    <h1 className="text-3xl font-bold text-center mb-8">MSME Registration Process</h1>
                    <p className='pr-20'>
                        MSME Registration process is completely online but you have to require the professional to fill-up your application and getting approval from the department. MSMED Act is not compulsory for the companies but it is always suggested for them in order to have Government backing up their company. As a result, numerous medium and small-scale industries are stepping forward to register their company. The whole process of registration is given below:
                    </p>
                    <p className='pr-20'>The registration process simply requires you an application for the MSME registration that has to be prepared and submitted to the respective government office according to the latest rules and regulations.</p>
                    <p>In addition to it, one has to file its personal details of the kind of the industry they want to run and some of the respective documents as mentioned below:</p>
                    <ol className="pr-6">

                        <li>#1 Your Aadhaar Number.                                                                      </li>
                        <li>#2 Name of Applicant.</li>
                        <li>#3 Social Category.</li>
                        <li>#4 Gender</li>
                        <li>#5 Name of Enterprise / Business.</li>
                        <li>#6 Type of Organization you are opting for.</li>
                        <li>#7 Your PAN number.</li>
                        <li>#8 Location of Plant you are setting up in a place.</li>
                        <li>#9 Your current office Address.</li>
                        <li>#10 Mobile Number.</li>
                        <li>#11 Your E- Mail ID.</li>
                        <li>#12 Date of Commencement of Business.</li>
                        <li>#13 Your Bank Account Number.</li>
                        <li>#14 Bank IFS Code.</li>
                        <li>#15 Main Business Activity of Enterprise</li>
                        <li>#16 NIC 2 Digit Code.</li>
                        <li>#17 Additional details about Business.</li>
                        <li>#18 Number of employees</li>
                        <li>#19 Investment in Plant & Machinery / Equipment</li>
                        <li>#20 Attachment of your scan copy of Aadhaar card</li>
                        <li>#21 The usual time is between 2-3 days to complete all the documentation of the registration.</li>

                    </ol>
                    <p className="mt-4 pt-10">In spite of the fact that registration process is really hassle free, some industry owners still feels necessary to complete all the formalities in order to avoid any type of difficulties thereafter.</p>

                    <div className=''>
                        <div className="mb-8">
                            <h2 className="text-3xl font-bold ">Benefits under MSME Registration Online:
                            </h2>
                            <p>MSME Registration provides to you right to get avail benefits under the MSME Scheme of the government’s. ​One of the outstanding advantage of registration is that the Government will include your company or business in the the Central and State Government schemes so one must get the complete advantage of the schemes. It will lead into superior awareness about the much-arrived schemes and complete transparency of the government working process. Numerous benchmark benefits include simple ingress to various government subsidies and bank loans. Ability to apply for different Government’s beneficial schemes and clarity in various laws such as exercise, banking direct taxes and much more. Below are the some important MSME Scheme Benefits under the Registration :-</p>

                        </div>
                    </div>

                    <h1 className="text-3xl font-bold mb-6">MSME Benefits and Schemes</h1>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Easy Bank Loans</h2>
                        <p className="text-gray-700">All businesses registered under the MSME can avail benefits regarding bank loans. Banks provide collateral-free loans where your property is not pledged. The government and SIDBI have created a separate legal entity to implement this scheme for all small and medium business enterprises.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Reservation Benefits</h2>
                        <p className="text-gray-700">The government has amended the industrial act where every small business registered under the MSME can avail of this scheme to increase production of goods and employment opportunities in the country.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Easy to Open Business Bank Account or Any Other License Registration</h2>
                        <p className="text-gray-700">The MSME Registration Certificate serves as legal entity proof of the business, making it easy to open a current bank account or mention your registration while applying for other licenses like GST Registration.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Participate in International Trade Fairs</h2>
                        <p className="text-gray-700">Small businesses can expand across India or internationally with exposure and support from the government. Benefits are provided for participation and special consideration in international and national trade fairs organized by the MSME Department.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Octroi Benefits under MSME</h2>
                        <p className="text-gray-700">Small enterprises pay octroi on goods and services, increasing unnecessary costs. This scheme provides refunds for octroi imposed on goods and services.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Stamp Duty and Registration Charges Waived Off</h2>
                        <p className="text-gray-700">Previously, enterprises expanding in IT Parks or SEZs availed benefits under the Stamp Act. Now, all small enterprises can also avail these benefits.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Exemption under Income Tax as Direct Tax</h2>
                        <p className="text-gray-700">Innovative small businesses improving society can benefit from income tax exemptions in initial years. Similar benefits are introduced under the Startup India scheme.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Reimbursement under Bar Code Registration</h2>
                        <p className="text-gray-700">Manufacturing units require barcode registration. This scheme provides subsidies for barcode registration.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Various Subsidies on NSIC Performance and Credit Rating</h2>
                        <p className="text-gray-700">Small enterprises can avail subsidies on various expenses through NSIC Performance.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Industrial Promotion Subsidy</h2>
                        <p className="text-gray-700">The government promotes small enterprises by providing subsidies for industrial promotion.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Protection from Delay Payments by Buyers</h2>
                        <p className="text-gray-700">Small businesses often face delayed payments. This scheme offers protection, allowing enterprises to file cases against buyers and settle disputes quickly.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Reduction in Interest Rates by Major Banks</h2>
                        <p className="text-gray-700">Major banks have policies for MSME loans, ensuring a portion of advances goes to micro and small enterprises.</p>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>40% of advances to micro and small enterprises in manufacturing with investment in plant and machinery up to Rs.10 lakh and for service enterprises with investment in equipment up to Rs.4 lakh.</li>
                            <li>20% of advances to manufacturing enterprises with investment in plant and machinery above Rs.10 lakh up to Rs.25 lakh and for service enterprises with investment in equipment above Rs.4 lakh.</li>
                            <li>60% of advances to micro enterprises.</li>
                        </ul>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Capital Subsidy</h2>
                        <p className="text-gray-700">Enterprises receive a 15% capital subsidy to purchase plants and machinery.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Security Deposit Waived Off</h2>
                        <p className="text-gray-700">Small enterprises with limited working capital benefit from waived security deposits for MSME registration.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Concession in Electricity Bills</h2>
                        <p className="text-gray-700">Small enterprises with MSME Udyog Aadhar Certificates can avail concessions in electricity bills in certain areas.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Benefits in ISO Certification</h2>
                        <p className="text-gray-700">Small enterprises applying for big projects can show their credibility with ISO Certification, subsidized under this scheme.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Preference in Government Tenders</h2>
                        <p className="text-gray-700">MSME certified enterprises are preferred in government tenders, a significant advantage for winning big tenders.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Subsidy on Patent Registration </h2>
                        <p className="text-gray-700">Innovative small firms requiring patent registration can avail a 50% waiver on patent government registration fees under this scheme.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">MSME Registration Process</h2>
                        <p className="text-gray-700">MSME Registration is done online, but each state has a separate DIC (District Industries Centre) department that approves the MSME Registration Certificate with the MSME Number. To avail schemes or benefits under MSME, contact your nearby DIC Department.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Documents Required for MSME Registration</h2>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Aadhar Card Copy: Mandatory for MSME Registration Online. For private limited company registration or partnership, partners' Aadhar cards are used.</li>
                            <li>Mobile Number Linking with Aadhar Card: Required for online MSME Registration. If the mobile number is not linked with Aadhar, alternative documents like PAN card copy are needed.</li>
                        </ul>
                    </section>
                </div>

            </div>
            <Footer />
            <ScrollButton />
        </div>



    )
}

export default Msme_registration