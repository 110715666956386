import React from 'react';
import img from '../images/Commerce.png';
import { Link } from 'react-router-dom';
import { FaChalkboardTeacher, FaRegistered, FaGavel, FaGraduationCap } from 'react-icons/fa';

const Intro = () => {
    return (
        <>
            <div className="bg-gray-100 mx-auto max-w-full p-2 md:p-12 h-auto lg:h-5/6" id='about'>
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex justify-center items-center">
                        <img alt="card img" className="rounded-lg shadow-lg w-full lg:max-w-md h-auto" src={img} />
                    </div>
                    <div className="flex flex-col justify-center lg:ml-8 lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="text-4xl lg:text-5xl text-purple-900 font-bold mb-4">PG Network: Legal Service & E-Learning Provider</h3>
                        <p className="text-lg lg:text-xl text-gray-700 my-4 leading-relaxed">
                            PG Network is committed to providing tech-driven legal services and comprehensive e-learning modules. We specialize in:
                        </p>
                        <ul className="my-3 text-lg lg:text-xl text-gray-700 font-semibold space-y-6">
                            <li className="flex items-center">
                                <FaChalkboardTeacher className="mr-3 text-purple-900 text-2xl" />
                                Tech-driven services, reducing operational costs.
                            </li>
                            <li className="flex items-center">
                                <FaGraduationCap className="mr-3 text-purple-900 text-2xl" />
                                Engaging e-learning modules,e books, training simulator, both live and recorded.
                            </li>
                            <li className="flex items-center">
                                <FaRegistered className="mr-3 text-purple-900 text-2xl" />
                                Business registration services across India.
                            </li>
                            <li className="flex items-center">
                                <FaGavel className="mr-3 text-purple-900 text-2xl" />
                                Simplifying legal compliance with easy-to-use tools.
                            </li>
                            <li className="flex items-center">
                                <FaChalkboardTeacher className="mr-3 text-purple-900 text-2xl" />
                                Empowering your growth through targeted e-learning.
                            </li>
                        </ul>
                        <Link to="/contact" className="text-white bg-purple-900 hover:bg-blue-800 transition-all duration-300 ease-in-out inline-flex items-center justify-center w-full max-w-xs lg:max-w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Join the Movement
                            <svg className="w-5 h-5 ml-2 group-hover:translate-x-2 transition-transform duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;
