// src/components/AboutUs.js
import React, { useEffect } from 'react';
import { FaUsers, FaRegLightbulb, FaHistory, FaEnvelope } from 'react-icons/fa';
import aboutUsImage from '../images/1_TjXUGjDSTAR-H3O2M9M50A.gif'; // Adjust the path as needed
import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import ScrollButton from './goTop';

const AboutUs = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })

    return (
        <div>
            <NavBar/>
            <br/>
            <br/>
            <br/>
        <div className="container mx-auto p-6">
            <h1 className="text-4xl font-bold text-center mb-6 text-indigo-600">About Us</h1>
            
            <div className="flex flex-wrap md:flex-nowrap items-center mb-12 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 p-6 rounded-lg shadow-2xl">
                <div className="md:w-1/2 pr-6 text-white">
                    <h2 className="text-3xl font-semibold mb-4">Welcome to PG Network</h2>
                    <p className="text-lg mb-2">PG Network is a dynamic and innovative organization, a technology-driven legal tech platform, dedicated to empowering individuals and businesses to reach their full potential. Our mission is to provide a platform for connection, growth, and success with learning.</p>
                    <p className="text-lg italic">“We Made Legal at Your FingerTips”</p>
                    <p className="text-lg italic">“We Trust to Educate Everyone”</p>
                </div>
                <div className="md:w-1/2 mt-6 md:mt-0">
                    <img src={aboutUsImage} alt="About Us" className="w-full rounded-lg shadow-lg transform transition duration-500 hover:scale-105"/>
                </div>
            </div>
            
            <section className="mb-12 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold mb-4 text-center text-indigo-600">Our Vision</h2>
                <div className="flex items-center mb-4">
                    <FaRegLightbulb className="text-4xl text-blue-500 mr-4 animate-spin" />
                    <p className="text-lg">Our vision is to create a global network of like-minded individuals and businesses, working together, teaching together, providing learning, and achieving excellence while making a positive impact in their communities.</p>
                </div>
            </section>

            <section className="mb-12 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold mb-4 text-center text-indigo-600">Our Team</h2>
                <div className="flex items-center mb-4">
                    <FaUsers className="text-4xl text-green-500 mr-4 animate-bounce" />
                    <p className="text-lg">Our team consists of dedicated professionals passionate about delivering exceptional service and support. With diverse expertise and experience, we are committed to helping our members succeed.</p>
                </div>
            </section>

            <section className="mb-12 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold mb-4 text-center text-indigo-600">Our History</h2>
                <div className="flex items-center mb-4">
                    <FaHistory className="text-4xl text-red-500 mr-4 animate-pulse" />
                    <p className="text-lg">PG Network was founded on the principles of connection, growth, and success. Since our inception, we have been dedicated to providing a platform for individuals and businesses to thrive.</p>
                </div>
            </section>

            <section className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold mb-4 text-center text-indigo-600">Get in Touch</h2>
                <div className="flex items-center mb-4">
                    <FaEnvelope className="text-4xl text-yellow-500 mr-4 animate-wiggle" />
                    <div className="text-lg">
                        <p>Subscribe to our plans today and become part of a vibrant community dedicated to empowering each other for success!</p>
                        <p>Let’s Connect on Email: <a href="mailto:info@pgnetwork.in" className="text-blue-500 underline">info@pgnetwork.in</a></p>
                        <p>Drop your Suggestion | Complaints: <a href="mailto:support@pgnetwork.in" className="text-blue-500 underline">support@pgnetwork.in</a></p>
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        <ScrollButton/>
        </div>
    );
};

export default AboutUs;
