import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll'; // Import animateScroll from react-scroll
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Contact from '../pages/Contact';
import Keypoints from '../components/Keypoints';
import Pricing from '../components/Pricing';
import ScrollButton from '../components/goTop';


const Home = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <Hero scrollToTop={scrollToTop} /> {/* Pass scrollToTop function to Hero component */}
            <Intro scrollToTop={scrollToTop} /> {/* Pass scrollToTop function to Intro component */}
            <Contact scrollToTop={scrollToTop} /> {/* Pass scrollToTop function to Contact component */}
            <Keypoints scrollToTop={scrollToTop} /> {/* Pass scrollToTop function to Keypoints component */}
            <ScrollButton/>   {/* <Pricing scrollToTop={scrollToTop} /> */}
            <Footer />
        </>

    )
}

export default Home;

