import React from 'react';
import { BsClock, BsCheckCircle, BsExclamationCircle } from 'react-icons/bs'; // Importing icons from react-icons

function Faq() {
    return (
        <div className="max-w-4xl mx-auto py-8">
            <div className="prose lg:prose-lg">
                <section>
                    <h1 className="text-2xl font-bold mb-4">General Instructions</h1>
                    <ol className="list-decimal pl-6 mb-6">
                        <li className="flex items-start">
                            <BsClock className="mt-1 mr-2 text-gray-500" />
                            Schedule the assessment well in advance
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Check your registered email id regularly for schedule notification, reminder and result mail
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Use Desktop / Laptop / Tablet computer to appear for the assessment
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Login to the PG NETWORK portal 10 minutes before the assessment
                        </li>
                        <li className="flex items-start">
                            <BsExclamationCircle className="mt-1 mr-2 text-yellow-500" />
                            Read the instructions carefully before proceeding for the assessment
                        </li>
                        <li className="flex items-start">
                            <BsExclamationCircle className="mt-1 mr-2 text-yellow-500" />
                            Ensure that your network doesn’t get disrupted during the assessment
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            The questions for Assessment will be MCQs (Multiple choice questions)
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Assessment will be on SAP, Microsoft and Efiling. There will be no assessment for Cambridge university press
                        </li>
                        <li className="flex items-start">
                            <BsExclamationCircle className="mt-1 mr-2 text-yellow-500" />
                            Do not click on reload button during the assessment
                        </li>
                        <li className="flex items-start">
                            <BsExclamationCircle className="mt-1 mr-2 text-yellow-500" />
                            Go through the Help document and video in the My assessment tab
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Once you complete your assessment, please click on Submit button
                        </li>
                    </ol>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Mock Test</h2>
                    <ol className="list-decimal pl-6 mb-6">
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Mock test will be enabled for the students so that they can practice and be ready for the assessment.
                        </li>
                        <li className="flex items-start">
                            <BsClock className="mt-1 mr-2 text-gray-500" />
                            Total number of questions will be 15
                        </li>
                        <li className="flex items-start">
                            <BsClock className="mt-1 mr-2 text-gray-500" />
                            The time duration for the Mock test is 20 minutes
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Student can practice as many times as they want
                        </li>
                        <li className="flex items-start">
                            <BsCheckCircle className="mt-1 mr-2 text-green-500" />
                            Student can schedule the mock and attend the test at that particular date and time
                        </li>
                    </ol>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Scheduling the Assessment</h2>
                    <ol className="list-decimal pl-6 mb-6">
                        <li>
                            <strong>Why is Scheduling the Assessment necessary?</strong>
                            <ol className="list-decimal pl-6 mt-2 mb-2">
                                <li>You can prepare yourself well in advance for the Assessment</li>
                                <li>You can schedule it as per the time slots and dates given in the Tab as per your choice.</li>
                                <li>You will be notified about the Assessment schedule immediately after you schedule it</li>
                            </ol>
                        </li>
                        <li>
                            <strong>How to schedule an assessment?</strong>
                            <ol className="list-decimal pl-6 mt-2 mb-2">
                                <li>Login to PG NETWORK Portal- <a href="https://pgnetwork.in/Elearning" className="text-blue-500">https://pgnetwork.in/Elearning</a></li>
                                <li>Go to “My Assessment” Tab</li>
                                <li>In the Tab, you can see the total no. of questions, Duration and maximum no. of attempts</li>
                                <li>Click on “Schedule” tab and you can see the date of scheduling the assessment and also the time slot. You can select the date and time from the same as per your convenience.</li>
                                <li>Once you schedule your assessment, you will receive an email from PG NETWORK, confirming the same</li>
                            </ol>
                        </li>
                        <li>
                            <strong>How will I know the dates for assessment?</strong>
                            <p>The dates will be notified via Email by ICMAI. You can also see assessment dates in PG NETWORK portal on the banner of My Courses page.</p>
                        </li>
                        <li>
                            <strong>When can I schedule the assessment?</strong>
                            <p>It is better to schedule the assessment well in advance. You have to schedule the assessment at least one day before the assessment.</p>
                        </li>
                        <li>
                            <strong>What happens If I miss a scheduled slot?</strong>
                            <p>The number of attempts will be reduced by 1 and the result will be shown as ‘Absent’.</p>
                        </li>
                        <li>
                            <strong>Can I change the date and time of scheduled assessment?</strong>
                            <p>No, you cannot change it.</p>
                        </li>
                        <li>
                            <strong>When will the start button be enabled?</strong>
                            <p>The start button will be enabled on the scheduled date and time.</p>
                        </li>
                    </ol>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">About the Assessment</h2>
                    <ol className="list-decimal pl-6 mb-6">
                        <li>
                            <strong>What is the assessment pattern?</strong>
                            <ol className="list-decimal pl-6 mt-2 mb-2">
                                <li>There will be 90 questions</li>
                                <li>The assessment duration is for 90 minutes</li>
                                <li>The maximum number of attempts are 3</li>
                                <li>The result of first 2 attempts will be displayed on the dashboard</li>
                                <li>The result of third attempt will be announced through mail within 3 days</li>
                            </ol>
                        </li>
                        <li>
                            <strong>Can I appear the assessment through Mobile/Laptop/ Desktop?</strong>
                            <p>We strongly recommended you to use Laptop/ Desktop.</p>
                        </li>
                        <li>
                            <strong>Is Webcam and microphone required during assessment?</strong>
                            <p>No, Webcam and microphone are not required during assessment.</p>
                        </li>
                        <li>
                            <strong>What is the pop up that comes after I click on start button?</strong>
                            <p>It is the “Instruction List”. Read it carefully and follow.</p>
                        </li>
                        <li>
                            <strong>What are the functions of each button in assessment page?</strong>
                            <ul className="list-disc pl-6 mt-2 mb-2">
                                <li>Back &#8592; To move to the previous question</li>
                                <li>Clear Answer &#8592; To clear the answer</li>
                                <li>Mark for Review & Next &#8592; You can mark the question for future review. If you want to review the question again</li>
                                <li>Next &#8592; To move to the next question</li>
                            </ul>
                        </li>
                        <li>
                            <strong>What does the colors on the right side of the assessment page indicate?</strong>
                            <ul className="list-disc pl-6 mt-2 mb-2">
                                <li>Green button indicates the Answered questions</li>
                                <li>Blue button indicates that you can review your questions later</li>
                                <li>Yellow button indicates the active questions</li>
                            </ul>
                        </li>
                        <li>
                            <strong>How can I re-check my answers at the end?</strong>
                            <p>Click on the review button on the right-hand bottom corner, you can review and edit the answers accordingly within the allotted time.</p>
                        </li>
                        <li>
                            <strong>What is Review/Submit button?</strong>
                            <p>When you click on Review/Submit button you can see all the questions and answers listed, you can either edit if you want or click on submit button after you complete the assessment.</p>
                        </li>
                        <li>
                            <strong>What happens If I lose internet connectivity during an assessment?</strong>
                            <p>Make sure that you have internet connectivity for 90 minutes at the time of assessment. Your answers will be saved only if the internet connection is available. You will have to submit the answers by clicking submit button and internet connectivity is necessary for submission.</p>
                        </li>
                        <li>
                            <strong>How much bandwidth should I need for attending assessment?</strong>
                            <p>We recommend a bandwidth above 4mbps.</p>
                        </li>
                        <li>
                            <strong>Which browser can be used for attending assessment?</strong>
                            <p>The assessment will work in all browsers. Chrome, Microsoft Edge, or Firefox is recommended.</p>
                        </li>
                    </ol>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Passing Policy</h2>
                    <ol className="list-decimal pl-6 mb-6">
                        <li>
                            <strong>What is the Passing Policy?</strong>
                            <p>Passing policy will be as per the norms of the ICMAI. As per the passing policy a student must get at least 40% marks in each section (SAP, Microsoft and Efiling), the total aggregate should be 50%.</p>
                        </li>
                        <li>
                            <strong>How many marks should I get to clear my assessment?</strong>
                            <p>There will be 90 questions for the assessment, to clear the assessment you must get minimum of</p>
                            <ul className="list-disc pl-6 mt-2 mb-2">
                                <li>18 marks out of 45 in SAP</li>
                                <li>12 marks out of 30 in Microsoft</li>
                                <li>6 marks out of 15 in Efiling</li>
                                <li>Total of 45 marks out of 90</li>
                            </ul>
                        </li>
                    </ol>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Result</h2>
                    <ol className="list-decimal pl-6 mb-6">
                        <li>
                            <strong>How will I know the result of my assessment?</strong>
                            <p>After you press the “Submit” button, immediately the result will be flashed on the screen. you will also be notified via email. Please note that the result will be declared immediately for the first two attempts and for the third attempt, the result will be declared only by email within 7 days.</p>
                        </li>
                        <li>
                            <strong>Will I know the scores after the assessment?</strong>
                            <p>Your result (Passed/Failed) will be displayed on the screen. The scores will not be displayed.</p>
                        </li>
                        <li>
                            <strong>What happens if I don’t clear the assessment in 3 attempts?</strong>
                            <p>If you don’t clear the assessment within 3 attempts, you will be charged for each re-assessment.</p>
                        </li>
                    </ol>
                </section>

                <section className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Certificate</h2>
                    <ol className="list-decimal pl-6 mb-6">
                        <li>
                            <strong>How can I download the certificate for skill training?</strong>
                            <p>To download an online certificate Login to <a href="https://pgnetwork.in/Elearning" className="text-blue-500">https://pgnetwork.in/Elearning</a> portal &#8592; Click on My certificates tab &#8592; Download</p>
                        </li>
                        <li>
                            <strong>Will I get the certificate if I fail in the assessment?</strong>
                            <p>No, you will get the certificate only if you clear the assessment.</p>
                        </li>
                        <li>
                            <strong>When will certificates be generated?</strong>
                            <p>After clearing the assessment, you can download the certificate from My certificate tab after September 15th 2021.</p>
                        </li>
                    </ol>
                </section>

                {/* Add more sections following the same structure */}
            </div>
        </div>
    );
}

export default Faq;
