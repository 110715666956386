import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';

const Hero = ({ scrollToTop }) => {
    return (
        <div className="relative h-screen bg-gradient-to-br from-purple-600 to-blue-600">
            {/* Overlay */}
            <div className="absolute inset-0"></div>
            <NavBar />
            
            {/* Main Content */}
            <div className="relative flex flex-col items-center justify-center h-full text-center text-white px-4 md:px-8 lg:px-12">
                <div className="max-w-2xl mx-auto">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 leading-tight">
                        Now Book Consultancy at Lowest Cost with P.G Network
                    </h1>
                    <p className="text-base md:text-lg lg:text-xl mb-4 md:mb-6">
                        Simply Fill-up Below Details Related to your Query/Question in category and get Connect with Our Expert
                    </p>
                    <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:justify-center">
                        <Link
                            to="/contact"
                            className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-4 py-2 text-base md:px-6 md:py-3 md:text-lg lg:px-8 lg:py-4 lg:text-xl shadow-xl rounded-full"
                        >
                            Book Now 
                            <svg className="w-5 h-5 md:w-6 md:h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Bottom Section */}
            <div className="absolute bottom-0 left-0 right-0 bg-white text-black px-4 py-4 md:px-6 md:py-6 lg:px-8 lg:py-8 shadow-xl flex flex-col md:flex-row justify-between items-center">
                <div className="text-center md:text-left mb-4 md:mb-0">
                    <h2 className="text-lg md:text-xl lg:text-2xl font-bold mb-2">Get Started with P.G Network</h2>
                    <p className="text-sm md:text-base lg:text-lg">
                        India's top-class Accounting & Tax Consultant, trusted by more than 10000+ happy clients & 1000+ social reviews
                    </p>
                </div>
                <div>
                    <Link
                        to="/contact"
                        className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-4 py-2 text-sm md:px-6 md:py-3 md:text-base lg:px-8 lg:py-4 lg:text-lg shadow-xl rounded-full"
                    >
                        Get Started
                        <svg className="w-5 h-5 md:w-6 md:h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Hero;
