import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import Notiflix from 'notiflix';
import ScrollButton from './goTop';

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const [bankName, setBankName] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [upiId, setUpiId] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://pgnetworkbackend-production.up.railway.app/api/refundrequest/refund', {
                bankName,
                ifscCode,
                upiId
            });

            // Clear input fields after successful submission
            setBankName('');
            setIfscCode('');
            setUpiId('');

            // Handle success case
            Notiflix.Report.success(
                'Success',
                response.data.message, // Display success message from server
                'Okay'
            );
        } catch (error) {
            // Handle different error scenarios
            if (error.response) {
                Notiflix.Report.failure(
                    'An error occurred',
                    error.response.data.message, // Display error message from server
                    'Okay'
                );
                console.error('Server responded with an error:', error.response.data);
            } else if (error.request) {
                Notiflix.Report.failure(
                    'An error occurred',
                    'No response received from server.',
                    'Okay'
                );
                console.error('No response received:', error.request);
            } else {
                Notiflix.Report.failure(
                    'An error occurred',
                    'Unable to submit refund form.',
                    'Okay'
                );
                console.error('Error setting up the request:', error.message);
            }
        }
    };

    return (
        <div className='bg-gray-100 min-h-screen'>
            <NavBar />

            <div className="container mt-20 mx-auto px-4 py-8 md:px-6 lg:px-8">
                <h1 className="text-4xl font-bold text-gray-900 mb-12 text-center">Refund and Cancellation Policy</h1>

                <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
                    <p className="text-lg text-gray-700 mb-6 font-semibold">
                        <b>Refund Policy Terms</b>
                    </p>
                    <ol className="list-inside space-y-4" style={{ listStyleType: 'upper-alpha' }}>
                        <li>In case of GST Registration: If your application is filed by us and the government rejects it due to document issues, you are eligible for a 50% refund of the amount.</li>
                        <p className='ml-5'> <b>  Ex -</b> if you will apply for the GST Registration and Govt Rejected the application due to Documents and thereafter order will be cancelled by yourside, you will eligible for 50% Refund Amount.
                        </p>
                        <li>If you cancel the order before the application is filed with the government, you are eligible for a 100% refund.</li>
                        <li>In the case of Pvt Ltd Company Incorporation or Trademark services, where high government charges apply, only the professional fee is eligible for a refund.</li>
                        <li>In the case of Income Tax Return Filing, a 100% refund is possible only if you cancel the order before the preparation of tax computation and ITR form drafting by the tax expert.</li>
                    </ol>

                    <p className="text-lg text-gray-700 mt-8 mb-4 font-semibold">
                        <b>Refund Process</b>
                    </p>
                    <p className="mb-4">Simply fill up the details below with your Bank and IFSC Code or UPI and generate a Refund Ticket Number.</p>
                    <p>You will receive a response from our Billing Team via email with your refund status within the next 4-5 business days.</p>
                </div>

                <div className="p-6 bg-white shadow-lg rounded-lg mb-12">
                    <h3 className="text-xl font-semibold mb-4">Refund Form</h3>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Bank Name</label>
                            <input
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">IFSC Code</label>
                            <input
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={ifscCode}
                                onChange={(e) => setIfscCode(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">UPI ID</label>
                            <input
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={upiId}
                                onChange={(e) => setUpiId(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                Generate Refund Ticket
                            </button>
                        </div>
                    </form>
                </div>

                {message && (
                    <div className="mt-4 p-4 bg-yellow-200 text-yellow-800 rounded-md">
                        {message}
                    </div>
                )}
            </div>

            <Footer />
            <ScrollButton />
        </div>
    );
};

export default RefundPolicy;
