import React, { useState } from 'react';
import { FaCcVisa, FaCcMastercard, FaCcPaypal, FaCcAmex, FaCcDiscover } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Function to dynamically load Razorpay SDK
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const PaymentCheckoutElearning = () => {
    const navigate = useNavigate();
    const amount = 2; // Amount for testing (can be dynamic based on user input)

    // Function to handle payment process
    async function handlePayment() {
        try {
            // Step 1: Load Razorpay SDK
            const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK failed to load. Please check your internet connection.");
                return;
            }

            // Step 2: Fetch data from backend to initiate payment
            const response = await fetch("https://pgnetworkbackend-production.up.railway.app/api/razorpayElearning", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ amount })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Response from backend:", data);

            // Step 3: Prepare options for Razorpay checkout
            const options = {
                key: "rzp_test_kZfbpOoRZlFKE9", // Replace with your Razorpay Key
                currency: data.currency,
                amount: data.amount.toString(),
                order_id: data.id,
                name: "PGnetwork",
                description: "",
                image: "https://pgnetworkbackend-production.up.railway.app/api/logo.svg",
                handler: function (response) {
                    // Step 4: Handle successful payment
                    alert("Transaction successful");
                    sendPaymentIdToBackend(response.razorpay_payment_id);
                },
                prefill: {
                    name: "",
                    email: "",
                    phone_number: "",
                },
            };

            // Step 5: Create Razorpay instance and open checkout
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Error processing payment. Please try again later.");
        }
    }

    // Function to send payment ID to backend and generate token
    async function sendPaymentIdToBackend(paymentId) {
        try {
            const response = await fetch("https://pgnetworkbackend-production.up.railway.app/api/generate-token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ paymentId })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Token generated:", data.token);

            // Store token and expiration time securely
            const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
            localStorage.setItem('token', data.token);
            localStorage.setItem('tokenExpiration', expirationTime);

            // Navigate to next page or perform actions after successful payment
            navigateToNext();

        } catch (error) {
            console.error("Error generating token:", error);
            alert("Error generating token. Please try again later.");
        }
    }

    // Function to navigate to next page after successful payment
    const navigateToNext = () => {
        navigate('/Elearningcontent'); // Replace with your actual route
    };

    return (
        <div className="min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
                <h2 className="text-center text-3xl font-extrabold text-gray-900">
                    Checkout
                </h2>
                <div className="flex justify-center space-x-4 mb-6">
                    <FaCcVisa className="text-3xl text-blue-500" />
                    <FaCcMastercard className="text-3xl text-red-500" />
                    <FaCcPaypal className="text-3xl text-blue-700" />
                    <FaCcDiscover className="text-3xl text-orange-500" />
                    <FaCcAmex className="text-3xl text-blue-900" />
                </div>
                <form className="mt-8 space-y-6">
                    <div className="mt-6">
                        <div className="flex justify-between items-center mb-3">
                            <p className='text-gray-700 text-xl'>
                                Account name – PRAKHAR GUPTA <br />
                                Account number – 250405000881 <br />
                                IFSC – ICIC0002504 <br />
                                Type – Current Account
                            </p>
                            {/* <label htmlFor="amount" className="text-gray-700">Amount:</label>
                            <label htmlFor="amount" className="text-gray-700">2</label> */}
                        </div>
                    </div>

                    <div className="mt-6">
                        <button type="button" onClick={handlePayment} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Pay Now
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PaymentCheckoutElearning;
