import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
import Notiflix from 'notiflix';
import { FaGlobe, FaMailchimp, FaMapMarkerAlt, FaPersonBooth, FaPhoneAlt, FaRegAddressBook, FaUserClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Contact = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })
    useDocTitle('PG Network  Send us a message');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState([]);

    const clearErrors = () => {
        setErrors([]);
    };

    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setCategories([]);
    };

    // const sendEmail = (e) => {
    //     e.preventDefault();
    //     document.getElementById('submitBtn').disabled = true;
    //     document.getElementById('submitBtn').innerHTML = 'Loading...';
    //     let fData = new FormData();
    //     fData.append('first_name', firstName);
    //     fData.append('last_name', lastName);
    //     fData.append('email', email);
    //     fData.append('phone_number', phone);
    //     fData.append('message', message);
    //     categories.forEach((category) => {
    //         fData.append('categories[]', category);
    //     });
        

    //     axios({
    //         method: "post",
    //         url: "https://pgnetworkbackend-production.up.railway.app/api/contact",
    //         data: fData,
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //         .then(function (response) {
    //             document.getElementById('submitBtn').disabled = false;
    //             document.getElementById('submitBtn').innerHTML = 'Send Message';
    //             clearInput();
    //             //handle success
    //             Notiflix.Report.success(
    //                 'Success',
    //                 response.data.message,
    //                 'Okay',
    //             );
    //         })
    //         .catch(function (error) {
    //             document.getElementById('submitBtn').disabled = false;
    //             document.getElementById('submitBtn').innerHTML = 'Send Message';
    //             //handle error
    //             const { response } = error;
    //             if (response.status === 500) {
    //                 Notiflix.Report.failure(
    //                     'An error occurred',
    //                     response.data.message,
    //                     'Okay',
    //                 );
    //             }
    //             if (response.data.errors !== null) {
    //                 setErrors(response.data.errors);
    //             }

    //         });
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post('https://pgnetworkbackend-production.up.railway.app/api/contact', {
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phone,
                message,
                categories
            });
    
            console.log('Form submitted:', response.data);
            // Optionally, you can reset form fields after successful submission
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setCategories([]);
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'Send Message';
            clearInput();
    
            // Handle success
            Notiflix.Report.success(
                'Success',
                response.data.message, // Corrected to use response.data.message
                'Okay'
            );
    
            // Display success message or redirect to a thank you page
            alert('Form submitted successfully!');
        } catch (error) {
            console.error('Form submission error:', error.response.data);
            Notiflix.Report.failure(
                'An error occurred',
                error.response.data.message, // Corrected to use error.response.data.message
                'Okay'
            );
            setErrors(error.response.data);
        }
    };
    
    const handleCategoryChange = (e) => {
        const { value } = e.target;
        if (categories.includes(value)) {
            setCategories(categories.filter((category) => category !== value));
        } else {
            setCategories([...categories, value]);
        }
    };

    return (
        <div className=''>
        
            <div id='contact' className="flex justify-center items-center mt-0 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-0 px-4 lg:px-20" data-aos="zoom-in">

                    <div className="text-center mb-8">
                        <h1 className="text-4xl lg:text-6xl font-bold text-purple-900">Book Free Consultation Right Now</h1>
                        <p className="text-lg lg:text-2xl text-gray-700">PG Network Smart Contact Form</p>
                    </div>

                    <form  className="w-full">

                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="First Name*"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="last_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="phone_number"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder="Contact Number*"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    }
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea
                                    name="message"
                                    placeholder="Message*"
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                    required
                                ></textarea>
                                {errors &&
                                    <p className="text-red-500 text-sm">{errors.message}</p>
                                }
                            </div>

                            <div className="w-1/2 pr-4">
                                <h2 className="text-2xl">Support Team Categories*</h2>
                                <div className="mt-2">
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="Company Registration"
                                            checked={categories.includes('Company Registration')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        Company Registration
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="GST Related"
                                            checked={categories.includes('GST Related')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        GST Related
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="Income Tax Return"
                                            checked={categories.includes('Income Tax Return')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        Income Tax Return
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="MSME/Udyog Aadhar"
                                            checked={categories.includes('MSME/Udyog Aadhar')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        MSME/Udyog Aadhar
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="Trademark Registration"
                                            checked={categories.includes('Trademark Registration')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        Trademark Registration
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="Food License/FSSAI"
                                            checked={categories.includes('Food License/FSSAI')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        Food License/FSSAI
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="Annual Filing Pvt Ltd/LLP"
                                            checked={categories.includes('Annual Filing Pvt Ltd/LLP')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        Annual Filing Pvt Ltd/LLP
                                    </label>
                                    <label className="block text-gray-900">
                                        <input
                                            type="checkbox"
                                            name="category"
                                            value="Others"
                                            checked={categories.includes('Others')}
                                            onChange={handleCategoryChange}
                                            className="mr-2"
                                        />
                                        Others
                                    </label>
                                </div>
                                {errors &&
                                    <p className="text-red-500 text-sm">{errors.category}</p>
                                }
                            </div>
                        <div className='w-1/2 pr-4'>
                        <h6 className="text-xl font-bold mb-3 flex items-center">
                            <FaRegAddressBook className="mr-2" />
                            Contact Information
                        </h6>
                        <ul className="text-base space-y-2">
                        <li className="flex items-center">
                                <FaPersonBooth className="mr-2" />
                                <span>PRAKHAR GUPTA
                                </span>
                            </li>
                            <li className="flex items-center">
                                <FaMapMarkerAlt className="mr-2" />
                                <span>SECTOR 49 NOIDA DISTRICT GAUTAM BUDDHA NAGAR UP -201301
                                </span>
                            </li>
                            <li className="flex items-center">
                                <FaMailchimp className="mr-2" />
                                <span>SUPPROT@PGNETWORK.IN </span>
                            </li>
                            <li className="flex items-center">
                                <FaUserClock className="mr-2" />
                                <Link to="#" className="hover:text-yellow-300" >
                                    Timings:- 9:00 AM to 5:45 PM
                                </Link>
                            </li>
                            <li className="flex items-center">
                                <FaGlobe className="mr-2" />
                                <a href="https://www.pgnetwork.in/" className="hover:text-yellow-300" target="_blank" rel="noopener noreferrer">
                               www.pgnetwork.in/
                                </a>
                            </li>
                        </ul>
                    </div>

                            <div className="my-2 w-1/2 lg:w-2/4 mx-auto">
                                <button type="submit" id="submitBtn" onClick={handleSubmit} className="bg-purple-900 text-white w-full py-3 rounded-lg focus:outline-none focus:shadow-outline hover:bg-purple-700 transition duration-300">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
      
        </div>
    );
};

export default Contact;
