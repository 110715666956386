import React from 'react'
import NavBar from '../Navbar/NavBar'
import Contact from '../../pages/Contact';
import { Link } from 'react-router-dom'

import Footer from '../Footer'

function GstFiling() {
    return (
        <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>

            <div className="absolute inset-0 "></div>
            <NavBar />

            <div className="relative flex flex-col items-center justify-center h-full text-center text-white">

                <div className="px-6 md:px-12 lg:px-24 xl:px-32">
                    <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">
                        Gst Return Filing Online
                    </h1>
                    <p className="text-xl md:text-2xl lg:text-xl mb-6">#1 Portal to get done gst return filing at cheapest cost with simple 3 steps
                        Package Start From Just Only 499/- Per Month</p>
                    <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                        <Link
                            to="/contact"
                            className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                        >
                            GET PROPOSAL
                            <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </Link>
                    </div>

                </div>
            </div>

            <div className="px-16">
                <div className="max-w-full mx-auto bg-white  ">
                    <div>
                        <h2 className="text-3xl font-bold mb-4">What is GST Return Filing ?
                        </h2>
                        <p className="mb-4">Every person who is applying for the GST Registration has to file GST return Filing. GST Return is mandatory for each person whose enroll under the GST Registration.There is a lot of the type of the GST Returns which you have to file the monthly basis with one annual return. A registered personal have to file the returns about the following things –</p>
                        <ol className="pr-6">
                            <li>#1 Total Sales of the Month    </li>
                            <li>#2 Total Output GST Tax</li>
                            <li>#3 Total Purchase of the Month</li>
                            <li>#4 Total Input GST Credit Tax</li>
                        </ol>
                        <p>if you are registered under the GST Composition Scheme then there are the different rules for the same.</p>
                    </div>
                </div>


                <div className="max-w-full mx-auto px-4 0 py-8">
                    <div className="relative" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>
                        <div className="max-w-7xl  mx-auto a ">
                            <div>
                                <br />
                                <br />
                                <h4 className="text-3xl text-white font-bold mt-6 mb-2">​GST Return Filing Fee
                                </h4>

                            </div>
                            <h6 className="text-3xl text-white font-bold mt-6 mb-2">Pricing : 499/- INR Per Month
                            </h6>
                            <h6>Include:</h6>


                            <ol className="pr-6 text-white">

                                <li>  #1 Collection of the Data and Preparation of the Tax Data in Govt Format</li>
                                <li>  #2 GSTR3B Return Filing by CA</li>
                                <li>  #3 GSTR1 Return Filing by CA</li>
                                <li>  #4 GST Tax Consultancy by CA</li>
                            </ol>
                            <p>*Avail 3 Month GST Return Filing Package</p>

                            <br />
                            <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                                <Link
                                    to="/contact"
                                    className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                                >
                                    Get Booking
                                    <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                           


                        </div>
                        <Contact />
                    </div>
                </div>
                <div className="p-8">
                    <h1 className="text-3xl font-bold mb-6">GST Return Date: GST Return Filing Date</h1>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Types of Filing Dates</h2>
                        <div className="mb-6">
                            <h3 className="text-xl font-semibold">GSTR 3B</h3>
                            <p className="mb-2">This is mainly a GSTR Return filing summary where you have to provide the data of the sales and purchase for the same & make the tax payment. The due date depends on the aggregate turnover of the company.</p>
                            <p className="mb-2">There are 2 criteria for the same:</p>
                            <ul className="list-disc ml-6 mb-4">
                                <li>
                                    <strong>If your firm or business turnover is less than 1.5 Cr in a year:</strong> GSTR3B always be filed on each month of 20th. For example, in June 2018 month, the due date is 20th July 2018.
                                </li>
                                <li>
                                    <strong>If your business aggregator turnover is more than 1.5 Cr in a year:</strong> GSTR3B always be filed on each month of 20th as mentioned above.
                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h3 className="text-xl font-semibold">GSTR 1</h3>
                            <p className="mb-2">This is also called the sales returns because you have to provide all the sales details in this return. You have to mention each dealer B2B details with their GSTIN Number. All the outward supplier details are mentioned.</p>
                            <ul className="list-disc ml-6 mb-4">
                                <li>
                                    <strong>If your aggregator turnover is less than 1.5 Cr:</strong> The due date for GSTR 1 is on a quarterly basis. For example, April to June 2018 - Due date will be 31st July 2018.
                                </li>
                                <li>
                                    <strong>If your aggregator turnover is more than 1.5 Cr:</strong> You have to file on a monthly basis. For example, June 2018 month - You have to file in July 2018 before the 10th.
                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h3 className="text-xl font-semibold">GSTR 2</h3>
                            <p>This is an auto-populate return where all the input tax credit details have been mentioned. All the purchase details are also mentioned in GSTR 2. Currently, GSTR 2 is not practically filed, so you have to wait for the same.</p>
                        </div>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">How to File GST Return</h2>
                        <p className="mb-2">GST Return Filing is an online procedure. If you have GST Software, it will be easy for you to file GST Return. Even GST Portal issued an offline excel utility which is very easy to file the GST Returns, but you need knowledge about the returns.</p>
                        <p className="mb-4">Here are the simplified steps to file your GSTR Returns:</p>
                        <ol className="list-decimal ml-6 mb-4">
                            <li>Login with your user ID and password on the GST Portal for GST e-filing.</li>
                            <li>Proceed with the GSTR-3B Return tab and insert your sales amount with the tax & purchase amount with the input credit.</li>
                            <li>Make the payment of the GST by creating the challan & submit the GST e-filing.</li>
                        </ol>
                        <p className="italic">Note: In GSTR 1, you have to upload each invoice detail.</p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">GST Return Filing Procedure</h2>
                        <p className="mb-4">We at MyOnlineCA build a simplified excel sheet where you just have to maintain your invoice records' basic details. At the end of the month, you can file your returns through our GST Experts assistance plan.</p>
                        <ol className="list-decimal ml-6 mb-4">
                            <li>Download the MyOnlineCA Simplified Excel Template to maintain your invoice records easily.</li>
                            <li>Email the prepared data to MyOnlineCA GST Experts for review and preparation of GSTR1, 2, and 3.</li>
                            <li>Once the data is finalized, our GST Expert files your GSTR Returns with the proper challan and generates the ARN Number.</li>
                        </ol>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">All about GST Software</h2>
                        <p className="mb-4">There are many good GST Software in the market, but they can be expensive for small businesses without knowledge about GST Returns and Accounts. Instead of expensive software, you can use MyOnlineCA GST assistant plan where you maintain records in a simplified excel sheet and file returns.</p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">GST Payment Due Date</h2>
                        <p className="mb-4">You can make GST payments via online net banking, NEFT Transfer, or eChallan. Generate the challan through your dashboard and make the GST Payment. You need an online internet banking account for GST Payments. During the filing of GSTR3B, you have to make the payment in advance on a monthly basis.</p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Documents Required for GST Return Filing</h2>
                        <p className="mb-4">GST Return filing requires mainly the sales invoice of taxable services or goods. Follow some standard guidelines:</p>
                        <ul className="list-disc ml-6 mb-4">
                            <li>Prepare each invoice as per the GST Law.</li>
                            <li>Maintain records online or offline as bookkeeping.</li>
                            <li>Try to maintain records in your GST Filing software or use our simplified excel sheets.</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Types of GST Return Forms</h2>
                        <ul className="list-disc ml-6 mb-4">
                            <li>GSTR-1: Details of all outward supplies of taxable goods and services, filed by registered taxable supplier. Due date: 10th of the next month.</li>
                            <li>GSTR-2: Details of all inward supplies of taxable goods and services, filed by registered taxable recipient. Due date: 15th of the next month.</li>
                            <li>GSTR-3: Automatically filled based on outward and inward supplies with tax payment furnished in GSTR-1 and 2. Prepared by 20th of the next month.</li>
                            <li>GSTR-4: Contains details of outward supplies, tax payable, and tax payment. Due date: 18th of the month succeeding quarter.</li>
                            <li>GSTR-5: Contains details of outward supplies, imports, input tax, tax paid, and remaining stock. Filed by non-resident taxable person by 20th of the next month.</li>
                            <li>GSTR-6: Contains details of return for input service distributor, filed by input service distributor by 13th of the next month.</li>
                            <li>GSTR-7: Contains details of the tax deduction. Due date: 10th of the next month.</li>
                            <li>GSTR-8: Contains details of all supplies made through e-commerce operator and tax collected. Filed by e-commerce operator or tax collector by 10th of every month.</li>
                            <li>GSTR-9: Annual return filed by registered taxable person by 31st December of next financial year.</li>
                            <li>GSTR-10: Final return filed by taxable person whose registration has been surrendered or cancelled within three months of the date of cancellation or surrender, whichever is later.</li>
                            <li>GSTR-11: Details of inward supplies furnished by a person with Unique Identification Number (UIN) by 28th of the month following the month for which the statement is filed.</li>
                            <li>GSTR-3B: Details of taxable supply of goods and services in the month of July and Aug 2017. No invoice level information is required, only total values for each field.</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Important Current GST Return Filing Dates</h2>
                        <ul className="list-disc ml-6 mb-4">
                            <li>GSTR-3B (Jun 2018): Jul 20th, 2018</li>
                            <li>GSTR-5 (Jun 2018): Jul 20th, 2018</li>
                            <li>GSTR-6 (Jul'17 - Jun'18): Jul 31st, 2018</li>
                            <li>GSTR-4 (Apr-Jun, 2018): Jul 18th, 2018</li>
                            <li>GSTR-5A (Jun 2018): Jul 20th, 2018</li>
                            <li>GST TRAN-2: Jun 30th, 2018</li>
                            <li>RFD-10: Eighteen months after end of the quarter for which refund is to be claimed</li>
                            <li>Quarterly return for registered persons with aggregate turnover up to Rs. 1.50 Crores</li>
                            <li>GSTR-1 (Apr-Jun, 2018): Jul 31st, 2018</li>
                            <li>Turnover exceeding Rs. 1.5 Crores or opted to file monthly Return</li>
                            <li>GSTR-1 (Jun 2018): Jul 10th, 2018</li>
                            <li>GSTR-1 (May 2018): Jun 10th, 2018</li>
                        </ul>
                    </section>
                </div>
            </div>
            <Footer />
        </div>



    )
}

export default GstFiling