import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { FaChevronDown } from 'react-icons/fa';
import logo from '../../images/logo pg network.jpg'; // Adjust the path as necessary
import NavLinks from '../Navbar/NavLinks';

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out bg-white shadow-lg ${isScrolled ? 'bg-white shadow-lg' : ''}`}>
      <div className="flex items-center justify-between py-2 md:px-12 md:mx-12">
        <div className="flex items-center">
          <HashLink smooth to="/#hero">
            <img src={logo} alt="CoolBrains Logo" className="h-20" /> {/* Adjust height as needed */}
          </HashLink>
        </div>
        <div className="flex items-center">
          <div className="hidden lg:inline-block space-x-6">
            <NavLinks />
          </div>
          <button className="lg:hidden p-2 rounded-lg text-purple-900" onClick={toggleMenu}>
            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              {isOpen ? (
                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
              ) : (
                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              )}
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
     <div className={`lg:hidden fixed top-20 right-0 md-30 h-wrap w-64 bg-transparent shadow-xl transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
  <button
    data-drawer-target="default-sidebar"
    data-drawer-toggle="default-sidebar"
    aria-controls="default-sidebar"
    type="button"
    className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
  >
    <span className="sr-only">Open sidebar</span>
    <svg
      className="w-6 h-6"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
      ></path>
    </svg>
  </button>

  <aside
    id="default-sidebar"
    className={`fixed top-0 md-30 right-0 z-40 w-64 h-wrap transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} sm:translate-x-0`}
    aria-label="Sidebar"
  >
    <div className="h-wrap  md-30 px-3 py-4 overflow-y-auto bg-white dark:bg-white">
      
        <NavLinks/>
      
    </div>
  </aside>
</div>



    </nav>
  );
};

export default NavBar;
