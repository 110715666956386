import React, { useState } from 'react';
import NavBar from './Navbar/NavBar';
import { FiCheckCircle } from 'react-icons/fi'; // Import necessary icons from react-icons
import { FaTimes } from 'react-icons/fa'; // Import close icon

// Import your PDF, XLSX, and DOCX files dynamically based on the directory content
import PANModulePDF from '../images/Commerce.png';
import ITRModulePDF from '../images/Commerce.png';
import GSTModulePDF from '../images/Commerce.png';
import { Link } from 'react-router-dom';

function EFilingElearningContent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [hideNavbarAndFooter, setHideNavbarAndFooter] = useState(false);

  // Function to open the file viewer modal
  const openFileViewer = (filePath) => {
    setSelectedFile(filePath);
    setHideNavbarAndFooter(true); // Hide navbar and footer when opening file viewer
  };

  // Function to close the file viewer modal
  const closeFileViewer = () => {
    setSelectedFile(null);
    setZoomLevel(1); // Reset zoom level when closing
    setHideNavbarAndFooter(false); // Show navbar and footer when closing file viewer
  };

  // Function to zoom in the file
  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.2);
  };

  // Function to zoom out the file
  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };

  return (
    <div>
      {!hideNavbarAndFooter && <NavBar />} {/* Render navbar if not hiding */}
      
      <div className="max-w-4xl mt-40 mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">eFiling - Advanced Digital Literacy</h1>
        <p className="mb-4">
          eFiling is a training and certification to the students on advanced digital Literacy to obtain awareness and gain compliance capability on Governmental rules, regulations, compliances and benefits to become self-reliant and independent.
        </p>
        <p className="mb-4">
          As Finance students, knowledge on Statutory documentation of an organization is mandatory. As Government has given a thrust to paperless office and most of these mandatory documentation and filing are in Electronic form right from application to a PAN Card to all filing of returns all the processes are in electronic form. eFiling course looks at skilling the students to be industry ready it is imperative that they are fully knowledgeable on various documentation and the process of usage on electronic format.
        </p>
        <h2 className="text-2xl font-bold mb-2">Objectives</h2>
        <p className="mb-4">
          The course is designed to increase knowledge about various statutory norms of the country and also provide hands on experience through simulations. eFiling is an important and useful course to be taught across the student in an effort to inculcate the habits of compliance and eFiling to the next-gen.
        </p>
        <h2 className="text-2xl font-bold mb-2">Learning Outcomes</h2>
        <ul className="list-disc pl-8 mb-4">
          <li>Gain knowledge on compliance of statutory norms</li>
          <li>Practice Hands-on experience of eFiling</li>
          <li>Obtain knowledge on compliance around Income Tax, GST and other statutory norms</li>
        </ul>
        <h2 className="text-2xl font-bold mb-2">Learning Materials</h2>
        <p className="mb-4">
          Learning materials for this course can be accessed from the options below:
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          <button 
            onClick={() => openFileViewer(PANModulePDF)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            PAN Module
          </button>
          <button 
            onClick={() => openFileViewer(ITRModulePDF)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            ITR Module
          </button>
          <button 
            onClick={() => openFileViewer(GSTModulePDF)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            GST Module
          </button>
        </div>

        {/* File Viewer Modal */}
        {selectedFile && (
          <div className="fixed top-0 left-0 mb-10 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg shadow-lg relative">
              <button onClick={closeFileViewer} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
                <FaTimes className="text-xl" />
              </button>
              <div className="relative w-screen h-screen">
                {selectedFile.endsWith('.pdf') ? (
                  <embed
                    src={selectedFile}
                    type="application/pdf"
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                  />
                ) : (
                  <iframe
                    src={selectedFile}
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                  />
                )}
              </div>
              <div className="flex justify-center mt-2">
                <button onClick={zoomIn} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2">Zoom In</button>
                <button onClick={zoomOut} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Zoom Out</button>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center mb-4">
          <FiCheckCircle className="text-green-500 mr-2" size={24} />
          <span className="text-lg">Successfully enrolled!</span>
        </div>
      </div>

      {!hideNavbarAndFooter && (
        <footer className="bg-gray-800 text-white text-center py-4"> {/* Render footer if not hiding */}
          &copy; {new Date().getFullYear()} Cambridge University Press. All rights reserved.
        </footer>
      )}
    </div>
  );
}

export default EFilingElearningContent;
