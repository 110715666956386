import React from 'react'
import NavBar from '../Navbar/NavBar'

import { Link } from 'react-router-dom'

import Footer from '../Footer'
import ScrollButton from '../goTop'
function Private_limited_company_registration() {
  return (
    <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>

      <div className="absolute inset-0 "></div>
      <NavBar />

      <div className="relative flex flex-col items-center justify-center h-full text-center text-white">

        <div className="px-6 md:px-12 lg:px-24 xl:px-32">
          <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">
            Private Limited Company Registration in India : Start Pvt Ltd Company

          </h1>
          <p className="text-xl md:text-2xl lg:text-xl mb-6">Private Limited Company Registration by 100% Govt Certified Portal
            Just Fill-Up Below Details and Get Started Your Pvt Ltd Company Registration in Seconds with FREE Consultancy !</p>
          <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
            <Link
              to="/INCOMETAXFILING"
              className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
            >
              GET PROPOSAL
              <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-16">
        <div className="max-w-full mx-auto bg-white  rounded-lg shadow-md">
          <div>
            <h2 className="text-3xl font-bold mb-4">What is Private Limited Company Registration ?</h2>
            <p className="mb-4">​Private Limited Company Registration is one of the most popular legal entity in India. In simplified terms, its a proper company registration in India with the ministry of corporate affairs and gives you rights doing business anywhere in India or outside. MCA register your pvt ltd company registration and provide to you CIN Number with Certificate of Incorporation. At the end of the process, you have to simply open a current bank account on the Company Name on the basis of Certificate and Start your Company Operations.

            </p></div>

          <div>
            <h2 className="text-3xl font-bold mt-6 mb-2">What is Pvt Ltd Company Registration Rules ?            </h2>
            <p className="mb-4">​#1 Require minimum 2 directors or partner in the Private Limited Company.</p>
            <p className="mb-4">#2 Authorised Share Capital: 1 Lakh Rupees ( It’s just a share proposed value, doe’st not mean you have to invest 1 lakh rupees)</p>

          </div>





          <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>
            <div className="max-w-7xl mr-50 mx-auto a ">
              <div>
                <h2 className="text-3xl text-white font-bold mt-6 mb-2">Pvt Ltd Company Registration Fees
                </h2>
                <p className="mb-4 text-2xl text-white">
                  We at MyOnlineCA offers Cheapest Cost on Pvt Ltd Company Registration Fees
                </p>
              </div>


              <div className="mb-6">
                <p className="text-white text-xl mb-2">#1 2 Directors DIN + DSC.</p>
                <p className="text-white text-xl mb-2">#2 Name Approval of Company + MOA & AOA Drafting & Printing.</p>
                <p className="text-white text-xl mb-2">#3 Certificate of Incorporation + PAN & TAN of the Company.</p>
              </div>
              <div className="mb-6">
                <h1 className="text-white text-xl mb-2">Free with above package.</h1>
                <p className="text-white text-xl mb-2">#1 MSME Registration Certificate to avail govt benefits.</p>
                <p className="text-white text-xl mb-2">#2 10+ Premium Legal Agreements Template.</p>
              </div>


              <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                <Link
                  to="/INCOMETAXFILING"
                  className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                >
                  Book Now
                  <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </Link>
              </div>

            </div>

          </div>
        </div>


        <div className="max-w-full mx-auto px-4 0 py-8">
          <h1 className="text-3xl font-bold text-center mb-8">Pvt Ltd Company Registration Online
          </h1>
          <p className='pr-20 mb-10'>
            Pvt Ltd Company Registration Online is possible through official MCA Govt Website but the problem i.e you can’t do it your self the pvt ltd company registration. Because its required the additional digital signature Attestation from a Practicing Chartered Accountant or Company Secretary or Lawyer etc. So It’s mean you have to hire a Good CA to get done your Private Limited Company Registration in India anywhere.
          </p>
          <h1 className="text-3xl font-bold mb-8">Private Limited Company Registration Process</h1>
          <p className='pr-20 '>
            Simple 5 Steps Process to Register Your Private Limited Company in India</p>
          <ol className="pr-6 mb-2">
            <br /> <li> #1  Apply for the Digital Signature Certificate (DSC) for all the Person who’s are will be director in the Private Limited Company.                                                                                                              </li>
            <br />
            <li> #2 Apply for the DIN Registration for all the Person who’s will be director in the Pvt Ltd Company.</li>
            <br /><li> #3 Now Just check about the Private Limited Company Name availability on the MCA Portal & Apply for the Name approval of the Company. with RUN Form.</li>
            <br /> <li> #4 Once name has been approved, Proceed with the SPIC(INC 32) Private Limited Incorporation Form with the E-MoA & E-AOA of the Pvt Ltd Company.</li>
            <br /> <li> #5 Now Apply for the PAN & TAN Application for the Private Limited Company which is issued with he Certificate of Incorporation.</li>
          </ol>


          <div className=''>
            <div className="mb-8">
              <br /> <h1 className="text-3xl font-bold  mb-8">Documents required for Private Limited Company Registration
              </h1>
              <p>Below are the Documents Checklist required for the Private Limited Company Registration –</p>
              <br></br><p>Personal Level Directors Documents for the Private limited Company –</p>
              <ol className="pr-6 mb-2">
                #1 Directors Personal Pan Card Copy
                <br />
                #2 Directors Aadhar Card/Voter id/Passport/Driving License Copy anyone for the identity verification.
                <br /> #3 Passport Size Photograph
                <br />
                <br />   Company Level Documents for the Pvt Ltd Company Registration –
                <br />
                <br />
                #4 Company Business Address Proof like Electricity Bill Copy with the NOC or Rent Agreement or Sale Deed anyone

                for the more details checkout here about Documents Required for documents required for pvt ltd company
              </ol>
            </div>
          </div>

          <h1 className="text-3xl font-bold mb-6">Benefits of Private Limited Company Registration
          </h1>
          <p>Below are the benefits of the private limited company –</p>
          <ol className="pr-6 mb-2">

            <li>  #1 Easily Setup and recommend to Growing Startups.   </li>
            <li>  #2 Limited Liability for Members.</li>
            <li>  #3 More Credibility as Compare to others.</li>
            <li>  #4 Easily raise funds from Investors.</li>
            <li>  #5 ESOP Option are available to motivated Employees.</li>
            <li>  #6 Name Protection available.</li>
          </ol>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Pvt Ltd Company registration name availability
            </h2>
            <p className="text-gray-700">​Basically, in the private limited company, you have to choose a unique name. because once a name has been registered with the MCA then no one other company take over the same name. It’s just like Brand Registration.</p>
            <p className="text-gray-700">So you have to provide 3-4 name during the name approval process during the Private Limited Company Registration Process. once you provide the company name, then MCA will check the same and approved as per the name availability rules and regulations. you can check here about How to Search a Company Name.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Different Forms of Online Pvt Ltd Company Registration            </h2>
            <p className="text-gray-700">​If you thinking Private Limited Company is very expensive for the starting then you can choose below:-</p>
            – Proprietorship Firm
            <br/>
            – Partnership Firm<br/>
            – OPC Registration<br/>
            – LLP Registration<br/>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">What is the Cost Involved in the Private Limited Company Registration</h2>
            <p className="text-gray-700">The MSME Registration Certificate serves as legal entity proof of the business, making it easy to open a current bank account or mention your registration while applying for other licenses like GST Registration.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Participate in International Trade Fairs</h2>
            <p className="text-gray-700">Small businesses can expand across India or internationally with exposure and support from the government. Benefits are provided for participation and special consideration in international and national trade fairs organized by the MSME Department.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Octroi Benefits under MSME</h2>
            <p className="text-gray-700">Small enterprises pay octroi on goods and services, increasing unnecessary costs. This scheme provides refunds for octroi imposed on goods and services.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Stamp Duty and Registration Charges Waived Off</h2>
            <p className="text-gray-700">Previously, enterprises expanding in IT Parks or SEZs availed benefits under the Stamp Act. Now, all small enterprises can also avail these benefits.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Exemption under Income Tax as Direct Tax</h2>
            <p className="text-gray-700">Innovative small businesses improving society can benefit from income tax exemptions in initial years. Similar benefits are introduced under the Startup India scheme.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Reimbursement under Bar Code Registration</h2>
            <p className="text-gray-700">Manufacturing units require barcode registration. This scheme provides subsidies for barcode registration.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Various Subsidies on NSIC Performance and Credit Rating</h2>
            <p className="text-gray-700">Small enterprises can avail subsidies on various expenses through NSIC Performance.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Industrial Promotion Subsidy</h2>
            <p className="text-gray-700">The government promotes small enterprises by providing subsidies for industrial promotion.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Protection from Delay Payments by Buyers</h2>
            <p className="text-gray-700">Small businesses often face delayed payments. This scheme offers protection, allowing enterprises to file cases against buyers and settle disputes quickly.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Reduction in Interest Rates by Major Banks</h2>
            <p className="text-gray-700">Major banks have policies for MSME loans, ensuring a portion of advances goes to micro and small enterprises.</p>
            <ul className="list-disc list-inside text-gray-700">
              <li>40% of advances to micro and small enterprises in manufacturing with investment in plant and machinery up to Rs.10 lakh and for service enterprises with investment in equipment up to Rs.4 lakh.</li>
              <li>20% of advances to manufacturing enterprises with investment in plant and machinery above Rs.10 lakh up to Rs.25 lakh and for service enterprises with investment in equipment above Rs.4 lakh.</li>
              <li>60% of advances to micro enterprises.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Capital Subsidy</h2>
            <p className="text-gray-700">Enterprises receive a 15% capital subsidy to purchase plants and machinery.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Security Deposit Waived Off</h2>
            <p className="text-gray-700">Small enterprises with limited working capital benefit from waived security deposits for MSME registration.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Concession in Electricity Bills</h2>
            <p className="text-gray-700">Small enterprises with MSME Udyog Aadhar Certificates can avail concessions in electricity bills in certain areas.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Benefits in ISO Certification</h2>
            <p className="text-gray-700">Small enterprises applying for big projects can show their credibility with ISO Certification, subsidized under this scheme.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Preference in Government Tenders</h2>
            <p className="text-gray-700">MSME certified enterprises are preferred in government tenders, a significant advantage for winning big tenders.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Subsidy on Patent Registration </h2>
            <p className="text-gray-700">Innovative small firms requiring patent registration can avail a 50% waiver on patent government registration fees under this scheme.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">MSME Registration Process</h2>
            <p className="text-gray-700">MSME Registration is done online, but each state has a separate DIC (District Industries Centre) department that approves the MSME Registration Certificate with the MSME Number. To avail schemes or benefits under MSME, contact your nearby DIC Department.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Documents Required for MSME Registration</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>Aadhar Card Copy: Mandatory for MSME Registration Online. For private limited company registration or partnership, partners' Aadhar cards are used.</li>
              <li>Mobile Number Linking with Aadhar Card: Required for online MSME Registration. If the mobile number is not linked with Aadhar, alternative documents like PAN card copy are needed.</li>
            </ul>
          </section>
        </div>

      </div>
      <Footer />
      <ScrollButton/>
    </div>

  )
}

export default Private_limited_company_registration