// PaymentCheckout.js
import React, { useState } from 'react';
import { FaCcVisa, FaCcMastercard, FaCcPaypal, FaCcAmex, FaCcDiscover } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import Pricing from './Pricing';
import { Link } from 'react-router-dom';
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const PaymentCheckout = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(''); // State for amount input

  async function handlePayment() {
    localStorage.setItem("amount",amount);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const response = await fetch("https://pgnetworkbackend-production.up.railway.app/api/razorpay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ amount }) // Send amount to backend
    });

    const data = await response.json();
    console.log(data);

    const options = {
      key: "rzp_test_0tpemkHKm5K1Bc",
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: "PGNetwork",

      description: "",
      image: "https://pgnetworkbackend-production.up.railway.app/api/logo.svg",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);

        alert("Transaction successful");
        navigate('/');
      },
      prefill: {
        name: "",
        email: "",
        phone_number: "",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

  };



  return (


    <div className="min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" >
      {/* <Pricing/>
    </div> */}
    {/* <div className="min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"> */}
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">
          Checkout
        </h2>
        <div className="flex justify-center space-x-4 mb-6">
          <FaCcVisa className="text-3xl text-blue-500" />
          <FaCcMastercard className="text-3xl text-red-500" />
          <FaCcPaypal className="text-3xl text-blue-700" />
          <FaCcDiscover className="text-3xl text-orange-500" />
          <FaCcAmex className="text-3xl text-blue-900" />
        </div>
        <form className="mt-8 space-y-6">


          <div className="mt-6">
                        <div className="flex justify-between items-center mb-3">
                            <label htmlFor="amount" className="text-gray-700">Amount:</label>
                            <input id="amount" name="amount" type="text" value={amount} onChange={(e) => setAmount(e.target.value)} className="appearance-none rounded-md block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter amount" />
                        </div>  
                    </div>

          <div className="mt-6">
            <p className='text-gray-700 text-xl'>
              Account name – PRAKHAR GUPTA <br />
              Account number – 250405000881 <br />
              IFSC – ICIC0002504 <br />
              Type – Current Account
            </p>
            <Link to='/sucesscheckout' onClick={handlePayment} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Pay Now
            </Link>
          </div>
        </form>
      </div>
    </div>
  
  );
};

export default PaymentCheckout;
