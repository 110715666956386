// formpayment.js
import React, { useState } from 'react';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';

function FormPayment() {
 const navigate = useNavigate();
  const [transactionId, setTransactionId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    
    console.log({
      transactionId,
      name,
      email,
      phone
    });
    
    // Notify the user
    Notiflix.Notify.success('We have received your details. We will contact you soon.');
    setTimeout(() => {
       navigate('/');
      }, 2000);
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">
          Payment Details
        </h2>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="mt-6">
          <div className="mt-6">
            <label htmlFor="amount" className="block text-black">Amount:{localStorage.getItem("amount")}</label>
          </div>
            <label htmlFor="transaction-id" className="block text-gray-700">Transaction ID:</label>
            <input
              id="transaction-id"
              name="transaction-id"
              type="text"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter transaction ID"
              required
            />
          </div>
          
          <div className="mt-6">
            <label htmlFor="name" className="block text-gray-700">Name:</label>
            <input
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your name"
              required
            />
          </div>
          
          <div className="mt-6">
            <label htmlFor="email" className="block text-gray-700">Email:</label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div className="mt-6">
            <label htmlFor="phone" className="block text-gray-700">Phone:</label>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your phone number"
              required
            />
          </div>
          
          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormPayment;
