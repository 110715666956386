import React, { useState } from 'react'

import { Link as ScrollLink } from 'react-scroll';
import NavBar from '../components/Navbar/NavBar';
import { FaCheckCircle } from 'react-icons/fa';
import Footer from './Footer';
import { FiFileText } from 'react-icons/fi';
import axios from 'axios';
import Notiflix from 'notiflix';
import ScrollButton from './goTop';
function INCOMETAXFILING() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNumber: '',
        whatsapp: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://pgnetworkbackend-production.up.railway.app/api/formsubmititr/submitForm', formData);

            // Handle success case
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay'
            );

            // Reset form fields after successful submission
            setFormData({
                name: '',
                email: '',
                contactNumber: '',
                whatsapp: false,
                selectedPlan: ''
            });
        } catch (error) {
            // Handle error cases
            Notiflix.Report.failure(
                'An error occurred',
                error.response?.data.message || 'Unable to submit form.',
                'Okay'
            );
            console.error('Error submitting form:', error);
        }
    };
    return (
        <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>

            <div className="absolute inset-0 "></div>

            {/* Content */}
            <div className="relative flex flex-col items-center justify-center h-full text-center text-white">
                <NavBar />
                <div className="px-6 md:px-12 lg:px-24 xl:px-32">
                    <h1 className="text-6xl md:text-6xl lg:text-5xl font-bold mb-4 leading-tight">Income Tax eFiling</h1>
                    <p className="text-3xl md:text-2xl lg:text-3xl mb-6">Now File ITR Online with India’s Largest Tax Consultant at Lowest Cost.</p>
                    <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                    <ScrollLink
              to="bookus"  // Replace with the ID of the section you want to scroll to
              spy={true}
              smooth={true}
              offset={-70} // Adjust this value as per your header height
              duration={500}
              className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-3xl shadow-xl rounded-full sm:w-auto sm:mb-0"
              
            >
           
            
                            Proposal Now
                            <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                            </ScrollLink>
                    </div>
                </div>
            </div>

            {/* Bottom Section */}

            <div className="max-w-full mx-auto bg-white p-6 rounded-lg shadow-md">
                <div>
                    <h2 className="text-3xl font-bold mb-4">Introduction on Income Tax</h2>
                    <p className="mb-4">In India, there are two types of taxes: direct tax which is directly levied upon the income of the individual and another is indirect tax which is levied indirectly upon an individual. Example of direct tax is income tax and of indirect tax is Goods and Services Tax, service tax etc.</p>
                    <p className="mb-4">Every individual who receives income in India is subject to income tax under the Income Tax Act, 1961. Under Income Tax Act there can be income from 5 different heads which are Income from salary, house property, capital gains, business and profession and other sources. Income of individuals, Hindu undivided family, association of person, body of individuals firms and companies are taxed under the Income tax act.</p>
                </div>

                <div>
                    <h2 className="text-3xl font-bold mt-6 mb-2">What is Income Tax Return</h2>
                    <p className="mb-4">Income Tax Return is a form in which the taxpayer whether it be an individual or firm or Hindu undivided family, discloses details of its income, claims exemptions and deductions which are applicable on it and the amount of tax payable on such income. The Income Tax Return also reflects the amount of taxes paid by the taxpayer.</p>
                </div>

                <div>
                    <h2 className="text-3xl font-bold mt-6 mb-2">Income Tax eFiling</h2>
                    <p className="mb-4">Income Tax can be filed by the two way i.e is the Online Method and another offline Method. Now as per the Government Instructions all the income tax return will be filed only through ITR eFiling Method. Offline Method is not available.</p>
                    <ul className="list-disc ml-8 mb-4">
                        <li>If your taxable income is more than 2.5 lakh Rupees in a year</li>
                        <li>If you want to claim any TDS Refund</li>
                        <li>If you want to take the benefits of carry forward of the losses</li>
                        <li>If foreign travel expenses are more than 2 lakh rupees in a year</li>
                        <li>If electricity bill expenses are more than 1 lakh rupees in a year</li>
                        <li>If total business sales or turnover are more than 60 lakh rupees in a year</li>
                        <li>If you have TDS more than 25000 rupees in a year</li>
                    </ul>
                </div>


                <div className="flex items-center space-x-2 mt-6">
                    <FaCheckCircle className="w-6 h-6 text-green-500" />
                    <p className="text-green-500">You can file Income Tax Returns online for ease and convenience.</p>
                </div>


                <div className="relative h-screen" style={{ background: 'linear-gradient(135deg, #6747C7, #8A2FDF)' }}>
                    <div className="max-w-3xl mx-auto  p-6 ">
                        <h2 className="text-2xl font-bold text-white mb-6">Income Tax Return Filing Fee</h2>

                        <div className="mb-6">
                            <p className="text-white mb-2">#1 All Plans above Include Dedicated ITR Filing by Experienced Tax Consultant.</p>
                            <p className="text-white">#2 Providing Final Acknowledgement and ITR Form Delivery.</p>
                        </div>

                        <div className="flex flex-col space-y-4">
                            <div className="bg-gray-100 rounded-lg p-4">
                                <h3 className="text-lg font-bold mb-2">ITR-1 (Salary + Other Income)</h3>
                                <p>Service Fee – 999/- INR</p>
                            </div>

                            <div className="bg-gray-100 rounded-lg p-4">
                                <h3 className="text-lg font-bold mb-2">ITR-4 (Business + Other Income)</h3>
                                <p>Service Fee – 999/- INR</p>
                            </div>

                            <div className="bg-gray-100 rounded-lg p-4">
                                <h3 className="text-lg font-bold mb-2">ITR-2 (Salary + Stock Market + Other Income)</h3>
                                <p>Service Fee – 2499/- INR</p>
                            </div>

                            <div className="bg-gray-100 rounded-lg p-4">
                                <h3 className="text-lg font-bold mb-2">ITR-3 (Salary + Stocks + F&O + Intraday + Other Income)</h3>
                                <p>Service Fee – 2999/- INR</p>
                            </div>
                            <div className="space-y-4 md:space-y-0 md:flex md:justify-center">
                            <ScrollLink
              to="bookus"  // Replace with the ID of the section you want to scroll to
              spy={true}
              smooth={true}
              offset={-70} // Adjust this value as per your header height
              duration={500}
                                    className="text-white bg-black hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-3xl shadow-xl rounded-full sm:w-auto sm:mb-0"
                                >
                                    Book Now
                                    <svg className="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </ScrollLink>
                            </div>

                        </div>

                    </div>
                </div>
                <div id='bookus'>
                <h2 className="my-2 text-3xl text-purple-900 uppercase font-bold flex items-center justify-center">
          <FiFileText className="mr-2 text-4xl" />  {/* Icon */}
          Book Your Income Tax Filing
        </h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-purple-900 mb-8'></div>
                    </div>
                </div>

                <div className="max-w-5xl mx-auto bg-white p-6 rounded-lg shadow-md">


                    <h2 className="my-2 text-3xl  uppercase font-bold flex ">
                    <FiFileText className="mr-2 text-4xl" /> Income Tax Return Filing</h2>
                    <p className="mb-6">Fill up Below Form and Let's Start</p>

                    <div className="mt-8">
                        <h2 className="text-lg font-bold mb-4">We Have Following ITR Plans</h2>
                        <ul className="list-disc list-inside">
                            <li>a) Basic Salary or any other Income (ITR-1) - 999/-INR</li>
                            <li>b) Business Income (ITR-4) - 999/-INR</li>
                            <li>c) Salary+Stocks & MF Gain (ITR-2) - 2499/-INR</li>
                            <li>d) Salary+F&O and Intraday (ITR-3) - 2999/-INR</li>
                        </ul>
                        <p className="mt-4">
                            All Plans Include:
                        </p>
                        <ul className="list-disc list-inside">
                            <li># Maximum TDS Tax Refund as per law.</li>
                            <li># Filing by Experienced Tax Expert.</li>
                        </ul>
                    </div>

                    <div className="mt-8">
                        <h2 className="text-lg font-bold mb-4">How to Order ITR Filing Plan</h2>
                        <ol className="list-decimal list-inside">
                            <li>Fill up Below Form and Make Payment Online</li>
                            <li>Upload Docs and Info and Be Relax!</li>
                        </ol>
                    </div>
                    <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name*
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email*
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700">
                    Contact Number*
                </label>
                <input
                    type="tel"
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    pattern="[0-9]{10}"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <p className="mt-2 text-xs text-gray-500">Insert number without 0 or +91. Ex - 9876543210</p>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Are you on WhatsApp?</label>
                <div className="mt-1 flex items-center">
                    <input
                        id="whatsapp"
                        name="whatsapp"
                        type="checkbox"
                        checked={formData.whatsapp}
                        onChange={handleChange}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="whatsapp" className="ml-2 block text-sm text-gray-900">
                        Yes, I am on WhatsApp
                    </label>
                </div>
            </div>

            <div className="mb-4">
                <label htmlFor="selectedPlan" className="block text-sm font-medium text-gray-700">
                    Select ITR Filing Plan*
                </label>
                <select
                    id="selectedPlan"
                    name="selectedPlan"
                    value={formData.selectedPlan}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <option value="">Select...</option>
                    <option value="ITR-1">Basic Salary or any other Income (ITR-1) - 999/-INR</option>
                    <option value="ITR-4">Business Income (ITR-4) - 999/-INR</option>
                    <option value="ITR-2">Salary+Stocks & MF Gain (ITR-2) - 2499/-INR</option>
                    <option value="ITR-3">Salary+F&O and Intraday (ITR-3) - 2999/-INR</option>
                </select>
            </div>

            <div className="mt-6">
                <button
                    type="submit"
                    className="w-full bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Submit
                </button>
            </div>
        </form>

                </div>
                <div className="max-w-full mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Income Tax Return Information</h1>

      {/* Type of Income Tax Return Form */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4">Type of Income Tax Return Form</h2>
        <ol className="list-decimal pl-6">
          <li>ITR 1: For resident individuals with income up to Rs. 50 lakh.</li>
          <li>ITR 2: For individuals and HUFs without income from business or profession.</li>
          <li>ITR 3: For individuals and HUFs with income from business or profession.</li>
          <li>ITR 4: For presumptive income from business and profession.</li>
          <li>ITR 5: For firms, association of persons, and body of individuals.</li>
          <li>ITR 6: For companies other than those claiming exemption under Section 11.</li>
          <li>ITR 7: For persons and companies under specified sections of the Income Tax Act.</li>
        </ol>
      </div>

      {/* Income Tax E-filing Due Date */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4">Income Tax E-filing Due Date</h2>
        <p>
          The due date for E-filing income tax returns varies based on taxpayer category:
        </p>
        <ul className="list-disc pl-6">
          <li>July 31 for individuals, HUFs, etc.</li>
          <li>September 30 for businesses requiring audit.</li>
          <li>November 30 for taxpayers requiring Form No. 32CEB under section 92E.</li>
        </ul>
        <p className="mt-4">Late filing results in penalties.</p>
      </div>

      {/* Benefits of Income Tax Return Filing */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4">Benefits of Income Tax Return Filing</h2>
        <ol className="list-decimal pl-6">
          <li>Facilitates loans like vehicle or home loans.</li>
          <li>Enables refund claims.</li>
          <li>Allows carry forward of losses.</li>
          <li>Required for visa processing.</li>
          <li>Necessary for high-value insurance policies.</li>
          <li>Supports government tender applications.</li>
        </ol>
      </div>

      {/* Documents Required for Income Tax eFiling */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4">Documents Required for Income Tax eFiling</h2>
        <ul className="list-disc pl-6">
          <li>PAN Card Registration</li>
          <li>Aadhar Number</li>
          <li>Bank Account details</li>
          <li>Form 16, Form 16A, Form 26AS</li>
          <li>Investment details, Rent receipts</li>
          <li>Bank Passbook, Fixed deposit statement</li>
          <li>Proof of investment, Medical expenses receipts</li>
          <li>Proof of home loan interest, Share transaction statement</li>
          <li>GST Registration Number Details, if any</li>
        </ul>
      </div>

      {/* Steps to File Income Tax Return eFiling Online */}
      <div className="mb-8">
        <h2 className="text-3xl font-semibold mb-4">Steps to File Income Tax Return eFiling Online</h2>
        <ol className="list-decimal pl-6">
          <li>Visit <a href="https://incometaxindiaefiling.gov.in" className="text-blue-500 hover:underline">https://incometaxindiaefiling.gov.in</a> and create your e-filing account.</li>
          <li>Login using your PAN number and password.</li>
          <li>Link your Aadhar card under the profile settings.</li>
          <li>Download the correct ITR form and fill in details.</li>
          <li>Validate and calculate tax.</li>
          <li>Pay tax payable, if any, and provide challan details.</li>
          <li>Generate XML file and upload return on the website.</li>
          <li>Submit and receive acknowledgement.</li>
        </ol>
      </div>

      {/* Conclusion */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Conclusion for Income Tax Return</h2>
        <p>
          Every taxpayer, whether an individual, HUF, company, association of persons, etc., must file income tax returns. Filing income tax returns is straightforward and can be done online.
        </p>
      </div>
    </div>

            </div>
            <Footer/>
            <ScrollButton/>

        </div>
    )
}

export default INCOMETAXFILING