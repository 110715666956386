import React, { useState } from 'react';
import axios from 'axios';
import NavBar from '../Navbar/NavBar';
import loginimage from '../../images/consejos-sacar-maximo-partido-elearning.jpg';
import Footer from '../Footer';
import Notiflix from 'notiflix';
const SignupPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''

  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('https://pgnetworkbackend-production.up.railway.app/api/regi/register', {
            name: formData.name,
            email: formData.email,
            password: formData.password
        });

        console.log('Registration successful:', response.data);
        Notiflix.Report.success(
            'Success',
            'Registration successful! You can now login.',
            'Okay'
        );
        setSuccess('Registration successful!');
        setError('');
    } catch (error) {
        console.error('Registration failed:', error.response.data);
        Notiflix.Report.failure(
            'Registration failed',
            error.response.data.message || 'An unknown error occurred.',
            'Okay'
        );
        setError('Registration failed. Please try again.');
        setSuccess('');
    }
};


  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <div className="flex-grow relative">
        <img src={loginimage} alt="Creative" className="absolute inset-0 w-full h-full object-cover opacity-75" />
        <div className="relative z-10 flex items-center justify-center min-h-screen bg-black bg-opacity-50">
          <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-center text-gray-800">Sign Up</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              {error && <div className="text-red-500 text-sm">{error}</div>}
              {success && <div className="text-green-500 text-sm">{success}</div>}
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full px-3 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-3 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="block w-full px-3 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirm-password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="block w-full px-3 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div> */}
              <div>
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign Up
                </button>
              </div>
            </form>
            <p className="text-sm text-center text-gray-600">
              Already have an account?{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;
