import React, { useEffect } from 'react';
import { FaUser, FaFileAlt, FaPassport, FaMoneyBillAlt, FaHome, FaIdCard, FaBirthdayCake, FaFileSignature, FaHandshake, FaUserPlus, FaFileInvoice, FaTicketAlt, FaPlane, FaHotel, FaBookReader } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import NavBar from './Navbar/NavBar';
import Footer from './Footer';

import ScrollButton from './goTop';

const Districtservices = () => {
  useEffect(()=>{
    window.scroll(0,'smooth');
  })
  return (
    <div className=' bg-gray-100'>
      <NavBar />
      <div className="py-12" id="services">
        <br />
        <br />
        <br />
        <br />

        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-4 text-center text-3xl text-custom-blue font-bold">
              <Link to="/EdistrictServices" className="my-2 text-center text-3xl text-custom-blue font-bold underline">
                Services
              </Link>{" "}
              |{" "}
              <Link to="/Schemes" className="my-2 text-center text-3xl text-custom-blue font-bold ">
                Schemes
              </Link>
            </h2>
            <div className="flex justify-center items-center">
              <div className="w-24 h-2 bg-custom-blue"></div>
              <div className="mx-4 w-24 h-2 bg-custom-green"></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-custom-blue">
              Explore our services
            </h2>
          </div>

          <div className="px-12" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
              {/* Personal Affidavits */}

            </div>

            {/* Additional Headings and Service Cards */}
            <div className="mt-8">
              <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Personal Affidavits</h2>
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                <ServiceCard
                  icon={<FaMoneyBillAlt className="text-teal-500" />}
                  title="Income Certificate"
                  description="Verification of income for official purposes."
                  link="/income-certificate"
                />
                <ServiceCard
                  icon={<FaHome className="text-indigo-500" />}
                  title="Domicile Certificate"
                  description="Proof of residence within a specific jurisdiction."
                  link="/domicile-certificate"
                />
                <ServiceCard
                  icon={<FaIdCard className="text-pink-500" />}
                  title="Caste Certificate"
                  description="Certification of caste for government benefits."
                  link="/caste-certificate"
                />
                <ServiceCard
                  icon={<FaBirthdayCake className="text-blue-500" />}
                  title="Date of Birth Certificate"
                  description="Official proof of date of birth for legal purposes."
                  link="/dob-certificate"
                />

              </div>
            </div>





            <div className="mt-8">
              <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Jeevan Praman/ Passport/ Other Services</h2>
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                <ServiceCard
                  icon={<FaPassport className="text-blue-500" />}
                  title="Jeevan Praman"
                  description="Digital certification for pensioners."
                  link="/jeevan-praman"
                />
                <ServiceCard
                  icon={<FaPlane className="text-yellow-500" />}
                  title="Passport"
                  description="Travel document for international journeys."
                  link="/passport"
                />
                <ServiceCard
                  icon={<FaTicketAlt className="text-orange-500" />}
                  title="Rail Ticket"
                  description="Booking services for railway travel."
                  link="/rail-ticket"
                />
                <ServiceCard
                  icon={<FaPlane className="text-blue-500" />}
                  title="Flight Ticket"
                  description="Flight booking services for domestic and international travel."
                  link="/flight-ticket"
                />
                <ServiceCard
                  icon={<FaHotel className="text-red-500" />}
                  title="Hotel Booking"
                  description="Reservation services for hotels and accommodations."
                  link="/hotel-booking"
                />
                <ServiceCard
                  icon={<FaBookReader className="text-green-500" />}
                  title="Yatra Registration"
                  description="Registration for pilgrimage and religious journeys."
                  link="/yatra-registration"
                />
              </div>
              
            </div>



            <div className="mt-8">

            </div>
            <div className="mt-8">
              <h2 className="text-xl lg:text-2xl font-semibold text-custom-blue mb-4">Birth/Death/ Affidavits</h2>
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
                <ServiceCard
                  icon={<FaUser className="text-blue-500" />}
                  title="Personal Affidavits"
                  description="Get personalized affidavits tailored to your needs."
                  link="/personal-affidavits"
                />

                {/* Birth/Death Affidavits */}
                <ServiceCard
                  icon={<FaFileAlt className="text-red-500" />}
                  title="Birth/Death Affidavits"
                  description="Documentation for births and deaths with legal support."
                  link="/birth-death-affidavits"
                />

                {/* Affidavits */}
                <ServiceCard
                  icon={<FaFileInvoice className="text-green-500" />}
                  title="Affidavits"
                  description="Legal documents certified by authorized entities."
                  link="/affidavits"
                />

                {/* Jeevan Praman */}
                <ServiceCard
                  icon={<FaPassport className="text-purple-500" />}
                  title="Jeevan Praman"
                  description="Certification for pensioners under digital platforms."
                  link="/jeevan-praman"
                />

                {/* Passport */}
                <ServiceCard
                  icon={<FaPlane className="text-yellow-500" />}
                  title="Passport"
                  description="Facilitation services for obtaining passports."
                  link="/passport"
                />

                {/* Other Services */}
                <ServiceCard
                  icon={<FaBookReader className="text-orange-500" />}
                  title="Other Services"
                  description="Explore additional services for various needs."
                  link="/other-services"
                />
                <ServiceCard
                  icon={<FaFileInvoice className="text-green-500" />}
                  title="Issue of Duplicate Certificate/Marksheet Affidavit"
                  description="Replacement of lost educational certificates."
                  link="/duplicate-certificate"
                />
                <ServiceCard
                  icon={<FaFileSignature className="text-purple-500" />}
                  title="Rent Agreement (Residential/Commercial)"
                  description="Legal contract for residential or commercial property rental."
                  link="/rent-agreement-commercial"
                />
                <ServiceCard
                  icon={<FaHandshake className="text-yellow-500" />}
                  title="Gap in Education Affidavit"
                  description="Declaration of gaps in educational history for official purposes."
                  link="/education-gap-affidavit"
                />
                <ServiceCard
                  icon={<FaUserPlus className="text-orange-500" />}
                  title="Education Loan Affidavit"
                  description="Legal documentation for securing educational loans."
                  link="/education-loan-affidavit"
                />
                <ServiceCard
                  icon={<FaFileSignature className="text-red-500" />}
                  title="Rent Agreement"
                  description="Legal agreement for renting residential or commercial properties."
                  link="/rent-agreement"
                />
                <ServiceCard
                  icon={<FaFileInvoice className="text-teal-500" />}
                  title="Anti Ragging Affidavit"
                  description="Declaration against ragging activities in educational institutions."
                  link="/anti-ragging-affidavit"
                />
                <ServiceCard
                  icon={<FaFileSignature className="text-indigo-500" />}
                  title="Affidavit for Baptism Certificate"
                  description="Legal statement supporting the issuance of baptism certificates."
                  link="/baptism-affidavit"
                />
                <ServiceCard
                  icon={<FaHandshake className="text-pink-500" />}
                  title="Affidavit for Second / Third / Other Born Child"
                  description="Legal declaration regarding additional children."
                  link="/birth-affidavit"
                />
              </div>
            </div>
          </div>
        </section>
        <br />

      </div>
      <Footer />
      <ScrollButton/>
    </div>
  );
}

const ServiceCard = ({ icon, title, description, link }) => (
  <Link to="/contact" className="flex flex-col items-center bg-white transition-all ease-in-out duration-400 text-gray-700 hover:bg-purple-900 hover:text-white rounded-lg shadow-xl p-4 group w-56 h-56">
    <div className="flex items-center justify-center bg-gray-200 rounded-full w-16 h-16 mb-4">
      {icon}
    </div>
    <h2 className="font-semibold text-lg text-center">{title}</h2>
    <p className="text-sm text-center mt-2">{description}</p>
  </Link>
);

export default Districtservices;
