import React, { useState } from 'react';
import NavBar from './Navbar/NavBar';
import { FiCheckCircle } from 'react-icons/fi'; // Import necessary icons from react-icons
import { FaTimes } from 'react-icons/fa'; // Import close icon

// Import your PDF files
import AccountsReceivablePdf from '../images/Sappdf/ACCOUNTS RECEIVABLE.pdf';
import AccountsPayablePdf from '../images/Sappdf/Accounts payable Process.pdf';
import ReportsPdf from '../images/Sappdf/Reports.pdf';
import EndUserTransactionCodesPdf from '../images/Sappdf/END USER TRANSACTION CODES.pdf';
import QuestionBankPdf from '../images/Sappdf/SAP question bank.docx';
import SAPPdf from '../images/Sappdf/SAP.pdf';
import ScrollButton from './goTop';
function SapElearningContent() {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [hideNavbarAndFooter, setHideNavbarAndFooter] = useState(false);

  // Function to open the PDF viewer modal
  const openPdfViewer = (pdfPath) => {
    setSelectedPdf(pdfPath);
    setHideNavbarAndFooter(true); // Hide navbar and footer when opening PDF viewer
  };

  // Function to close the PDF viewer modal
  const closePdfViewer = () => {
    setSelectedPdf(null);
    setZoomLevel(1); // Reset zoom level when closing
    setHideNavbarAndFooter(false); // Show navbar and footer when closing PDF viewer
  };

  // Function to zoom in the PDF
  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.2);
  };

  // Function to zoom out the PDF
  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };

  return (
    <div>
      {!hideNavbarAndFooter && <NavBar />} {/* Render navbar if not hiding */}
      
      <div className="max-w-4xl mt-40 mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">SAP E-Learning Content</h1>
        <p className="mb-4">
          SAP is at the centre of today’s technology revolution. The market leader in enterprise application software,
          SAP helps organisations fight the damaging effects of complexity, generate new opportunities for innovation
          and growth, and stay ahead of the competition.
        </p>
        <h2 className="text-2xl font-bold mb-2">SAP Power User Program:</h2>
        <p className="mb-4">
          A comprehensive program that covers the basics of ERP, SAP navigation and key concepts in Finance.
          This program is ideal for students who would like to improve their employability in companies that run SAP.
          The course trains the learners to interact with SAP systems efficiently and practicing industry-relevant SAP
          transactions in simulated environment makes them ready to face the real world.
        </p>
        <h2 className="text-2xl font-bold mb-2">SAP Financial Accounting (FI)</h2>
        <p className="mb-4">
          SAP FI (Financial Accounting) is one of the core functional modules in SAP software. Company-wide control and
          integration of financial information is essential to strategic decision making for any organisation.
          SAP ERP Financials enables you to centrally track financial accounting data within an international framework of
          multiple companies, languages, currencies, and charts of accounts.
        </p>
        <h2 className="text-2xl font-bold mb-2">Learning Materials</h2>
        <p className="mb-4">
          Learning materials for this course will be provided in SAP Learning Hub platform.
          You will receive an email from SAP Learning Hub which will have details on how to register on the platform.
          Please contact support if you have not received the email from SAP Learning Hub.
        </p>
        <p className="mb-4"><strong>Note:</strong> Please check your email’s spam folder as well.</p>
        <h3 className="text-xl font-bold mb-2">More information on SAP Learning Hub platform:</h3>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <a href="https://www.sap.com/documents/2015/01/001f6a05-457c-0010-82c7-eda71af511fa.html" className="text-blue-600 hover:underline">
              Technical Readiness Checklist for SAP Learning Hub
            </a>
          </li>
          <li>
            <a href="https://www.sap.com/documents/2015/10/cc075575-447c-0010-82c7-eda71af511fa.html" className="text-blue-600 hover:underline">
              General User Guide
            </a>
          </li>
          <li className="ml-4">
            User Manual:
            <ul className="list-disc ml-6">
              <li>
                <button onClick={() => openPdfViewer(AccountsReceivablePdf)} className="text-blue-600 hover:underline">Accounts Receivable</button>
              </li>
              <li>
                <button onClick={() => openPdfViewer(AccountsPayablePdf)} className="text-blue-600 hover:underline">Accounts Payable</button>
              </li>
              <li>
                <button onClick={() => openPdfViewer(ReportsPdf)} className="text-blue-600 hover:underline">Report</button>
              </li>
              <li>
                <button onClick={() => openPdfViewer(EndUserTransactionCodesPdf)} className="text-blue-600 hover:underline">End user transaction codes</button>
              </li>
              <li>
                <button onClick={() => openPdfViewer(QuestionBankPdf)} className="text-blue-600 hover:underline">Question Bank</button>
              </li>
              <li>
                <button onClick={() => openPdfViewer(SAPPdf)} className="text-blue-600 hover:underline">SAP</button>
              </li>
            </ul>
          </li>
        </ul>

        {/* PDF Viewer Modal */}
        {selectedPdf && (
          <div className="fixed top-0 left-0 mb-10 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <button onClick={closePdfViewer} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
                <FaTimes className="text-xl" />
              </button>
              <div className="relative w-screen h-screen">
                <embed
                  src={selectedPdf}
                  type="application/pdf"
                  className="absolute top-0 left-0 w-full h-full"
                  style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                />
              </div>
              {/* <div className="flex justify-center mt-2">
                <button onClick={zoomIn} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2">Zoom In</button>
                <button onClick={zoomOut} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Zoom Out</button>
              </div> */}
            </div>
          </div>
        )}

        {/* Example of using Creative React Icons */}
        <div className="flex items-center mb-4">
          <FiCheckCircle className="text-green-500 mr-2" size={24} />
          <span className="text-lg">Successfully enrolled!</span>
        </div>
      </div>

      {!hideNavbarAndFooter && <footer className="bg-gray-800 text-white text-center py-4"> {/* Render footer if not hiding */}
        &copy; 2024 SAP E-Learning Content. All rights reserved.
      </footer>}

      <ScrollButton/>
    </div>
  );
}

export default SapElearningContent;
