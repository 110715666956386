import React, { useState } from 'react';
import NavBar from './Navbar/NavBar';
import { FiCheckCircle } from 'react-icons/fi'; // Import necessary icons from react-icons
import { FaTimes } from 'react-icons/fa'; // Import close icon

// Import your PDF, XLSX, and DOCX files dynamically based on the directory content
import SoftSkillsPDF from '../images/cambridge/CambridgeOne_How_To.pptx';
import EmployabilityPDF from '../images/cambridge/CambridgeOne_How_To.pptx';
import GDInterviewPDF from '../images/cambridge/CambridgeOne_How_To.pptx';
import { Link } from 'react-router-dom';

function CambridgeElearningcontent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [hideNavbarAndFooter, setHideNavbarAndFooter] = useState(false);

  // Function to open the file viewer modal
  const openFileViewer = (filePath) => {
    setSelectedFile(filePath);
    setHideNavbarAndFooter(true); // Hide navbar and footer when opening file viewer
  };

  // Function to close the file viewer modal
  const closeFileViewer = () => {
    setSelectedFile(null);
    setZoomLevel(1); // Reset zoom level when closing
    setHideNavbarAndFooter(false); // Show navbar and footer when closing file viewer
  };

  // Function to zoom in the file
  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.2);
  };

  // Function to zoom out the file
  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };

  return (
    <div>
      {!hideNavbarAndFooter && <NavBar />} {/* Render navbar if not hiding */}
      
      <div className="max-w-4xl mt-40 mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">Cambridge University Press Soft Skills & Employability Skills Course</h1>
        <p className="mb-4">
          Cambridge University Press is part of the University of Cambridge, recognized globally as a leading publisher in English Language Teaching.
        </p>
        <p className="mb-4">
          This course is designed for undergraduates or intermediate-level candidates, providing comprehensive coverage of new-age work skills requirements. It aims to make individuals more employability-ready by imparting core soft and employability skills through a single course.
        </p>
        <p className="mb-4">
          The course covers soft skills such as Attitude, Goal Setting, Time Management, Emotional Intelligence, Leadership, and Social Consciousness. It also focuses on employability skills including Preparing for Employment, Communication Skills, Presentation Skills, Team Work, and Workplace Etiquette.
        </p>
        <h2 className="text-2xl font-bold mb-2">Learning Materials</h2>
        <p className="mb-4">
          Learning materials for this course will be provided in the Cambridge One portal.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          <a href="https://www.cambridgeone.org/home"
            
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            Cambridge One Portal
          </a>
          <button 
            onClick={() => openFileViewer(EmployabilityPDF)} 
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
            Help Document
          </button>
          <a href='https://bookshelf.cambridge.org/#!/login'
          
            className="bg-white text-blue-600 border border-gray-300 rounded-lg p-4 hover:bg-gray-100"
          >
           Bookshelf
          </a>
        </div>

        {/* File Viewer Modal */}
        {selectedFile && (
          <div className="fixed top-0 left-0 mb-10 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg shadow-lg relative">
              <button onClick={closeFileViewer} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
                <FaTimes className="text-xl" />
              </button>
              <div className="relative w-screen h-screen">
                {selectedFile.endsWith('.pdf') ? (
                  <embed
                    src={selectedFile}
                    type="application/pdf"
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                  />
                ) : (
                  <iframe
                    src={selectedFile}
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left', transition: 'transform 0.3s ease' }}
                  />
                )}
              </div>
              <div className="flex justify-center mt-2">
                <button onClick={zoomIn} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2">Zoom In</button>
                <button onClick={zoomOut} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Zoom Out</button>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center mb-4">
          <FiCheckCircle className="text-green-500 mr-2" size={24} />
          <span className="text-lg">Successfully enrolled!</span>
        </div>
      </div>

      {!hideNavbarAndFooter && (
        <footer className="bg-gray-800 text-white text-center py-4"> {/* Render footer if not hiding */}
          &copy; {new Date().getFullYear()} Cambridge University Press. All rights reserved.
        </footer>
      )}
    </div>
  );
}

export default CambridgeElearningcontent;
