import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
import Notiflix from 'notiflix';
import Contact from './Contact';
import ScrollButton from '../components/goTop';

const ContactPage1 = () => {
    

    return (
        <div>
          <NavBar/>
          <br/>
          <br/>
          <br/>
      <Contact/>
      <Footer/>
      <ScrollButton/>
        </div>
    );
};

export default ContactPage1;
